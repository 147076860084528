import { LABEL_PRINTER_ADDRESS, LABEL_PRINTER_ID, LABEL_PRINTER_PORT } from '../lib/consts';
import { IWording } from '../models/menu.model';
import { IMenuItem, IOnlineOrder, ITransaction } from '@markocen/bubblenation-base/models';
import { IOrderDetail } from '../models/checkout.model';
import { getVendorText } from './onilne-order.service';

const createPrinter = (): Promise<{ ePosDev: any; printer: any; builder: any }> => {
  return new Promise((resolve, reject) => {
    try {
      const epson = (window as any).epson;
      if (!epson) {
        return reject('global epson not found!');
      }

      const ePosDev = new epson.ePOSDevice();

      ePosDev.connect(
        LABEL_PRINTER_ADDRESS,
        LABEL_PRINTER_PORT,
        (result: string) => {
          if (result === 'OK' || result === 'SSL_CONNECT_OK') {
            ePosDev.createDevice(
              LABEL_PRINTER_ID,
              ePosDev.DEVICE_TYPE_PRINTER,
              { crypto: false, buffer: false },
              (deviceObj: any, errorCode: string) => {
                if (deviceObj === null) {
                  return reject(errorCode);
                }

                return resolve({ ePosDev, printer: deviceObj, builder: new epson.ePOSBuilder() });
              },
            );
          } else {
            return reject(result);
          }
        },
        { eposprint: true },
      );
    } catch (ex) {
      reject(ex);
    }
  });
};

const shouldPrintOrder = (order: IOrderDetail | IMenuItem): boolean => {
  // need to print out waffle order or other drinks orders
  return !order._id.startsWith('BAK');
};

export function testLabelPrinter() {
  return new Promise<void>((resolve, reject) => {
    createPrinter().then(
      ({ ePosDev, printer, builder }) => {
        printer.addFeedPosition(builder.FEED_CURRENT_TOF);
        printer.addText(`Label Printer is working!\n`);
        printer.addFeedPosition(builder.FEED_PEELING);
        if (ePosDev.isConnected()) {
          printer.send();
          ePosDev.deleteDevice(printer, () => {
            ePosDev.disconnect();
            alert('Label Printer is Working!!!');
            resolve();
          });
        } else {
          alert('Printer not connected');
          reject('Printer not connected');
        }
      },
      (err) => {
        reject(err);
        alert(err);
      },
    );
  });
}

export async function printLabel(
  orderNumber: number,
  orders: IOrderDetail[],
  onlineOrder?: { name: string; type: IOnlineOrder['vendor'] },
) {
  return new Promise<void>((resolve, reject) => {
    createPrinter().then(
      ({ ePosDev, printer, builder }) => {
        const totalItems = (orders || []).reduce((pre, curr) => pre + curr.count, 0);
        let current = 0;

        if (orders.length === 1 && !shouldPrintOrder(orders[0])) {
          return resolve();
        }

        for (const order of orders) {
          if (shouldPrintOrder(order)) {
            for (let i = 0; i < order.count; i++) {
              current++;
              const onlineOrderText = onlineOrder
                ? onlineOrder.type === 'square'
                  ? `Online:${onlineOrder.name}`
                  : `${getVendorText(onlineOrder.type).text}:${onlineOrder.name}`
                : '';
              const texts = [
                `[${current}/${totalItems}]${
                  onlineOrder
                    ? onlineOrderText
                    : orderNumber === undefined
                    ? ``
                    : `Order #${orderNumber}`
                }`,
                `${order._id}`,
                `[${order.size.toUpperCase()}]`,
              ];

              const optionText = Array.from(new Set(order.modifiers.map((m) => m.value))).join(',');

              if (optionText) {
                texts.push(optionText);
              }

              order.noDefaultToppings.forEach((t) => texts.push(`- ${t.name}`));

              const extraToppingHash = {};
              order.extraToppings.forEach((t) => {
                if (!extraToppingHash[t.name]) {
                  extraToppingHash[t.name] = 0;
                }
                extraToppingHash[t.name] = extraToppingHash[t.name] + 1;
              });
              Object.keys(extraToppingHash).forEach((k) =>
                texts.push(`+ ${k} x ${extraToppingHash[k]}`),
              );

              if (order.note) {
                texts.push(order.note);
              }

              printer.addFeedPosition(builder.FEED_CURRENT_TOF);
              for (const t of texts) {
                printer.addText(`${t}\n`);
              }

              if (i !== order.count - 1) {
                printer.addFeedPosition(builder.FEED_NEXT_TOF);
              }
            }
          }
        }

        printer.addFeedPosition(builder.FEED_PEELING);

        if (ePosDev.isConnected() && orders.length > 0) {
          printer.onreceive = (res: any) => {
            ePosDev.deleteDevice(printer, () => {
              ePosDev.disconnect();
              resolve();
            });
          };
          printer.onerror = (err: any) => {
            alert(`Failed to receive send data: ${err && err.status}`);
            ePosDev.deleteDevice(printer, () => {
              ePosDev.disconnect();
              reject();
            });
          };
          printer.send();
        } else {
          ePosDev.deleteDevice(printer, () => {
            ePosDev.disconnect();
            alert('Failed to connect to printer');
            reject();
          });
        }
      },
      (err) => {
        reject(err);
        alert(err);
      },
    );
  });
}

export async function reprintLabel(transaction: ITransaction, menu: IMenuItem[]) {
  return new Promise<void>((resolve, reject) => {
    createPrinter().then(
      ({ ePosDev, printer, builder }) => {
        const totalItems = (transaction.items || []).reduce((pre, curr) => pre + curr.count, 0);
        let current = 0;

        if (transaction.items.length === 1) {
          const item = menu.find((m) => m._id === transaction.items[0]._id);
          if (!shouldPrintOrder(item)) {
            return resolve();
          }
        }

        for (const item of transaction.items || []) {
          const order = menu.find((m) => m._id === item._id);
          if (shouldPrintOrder(order)) {
            for (let i = 0; i < item.count; i++) {
              current++;
              const texts = [
                `[${current}/${totalItems}]${
                  transaction.orderId === undefined ? `` : `Order #${transaction.orderId}`
                }`,
                `[${order.code}] ${order._id}`,
              ];

              const optionText = item.tags.join(', ');

              if (optionText) {
                texts.push(optionText);
              }

              printer.addFeedPosition(builder.FEED_CURRENT_TOF);
              for (const t of texts) {
                printer.addText(`${t}\n`);
              }

              if (i !== item.count - 1) {
                printer.addFeedPosition(builder.FEED_NEXT_TOF);
              }
            }
          }
        }

        printer.addFeedPosition(builder.FEED_PEELING);

        if (ePosDev.isConnected() && transaction.items.length > 0) {
          printer.onreceive = () => {
            ePosDev.deleteDevice(printer, () => {
              ePosDev.disconnect();
              resolve();
            });
          };
          printer.onerror = (err: any) => {
            alert(`Failed to receive send data: ${err && err.status}`);
            ePosDev.deleteDevice(printer, () => {
              ePosDev.disconnect();
              reject();
            });
          };
          printer.send();
        } else {
          ePosDev.deleteDevice(printer, () => {
            ePosDev.disconnect();
            alert('Failed to connect to printer');
            reject();
          });
        }
      },
      (err) => {
        reject(err);
        alert(err);
      },
    );
  });
}

export function printReceipt(orders: IOrderDetail[], wordings: IWording): string {
  const notes: string[] = [];

  orders.forEach((o) => {
    const title = `${wordings[o._id].name}`;
    const price = `$${o.totalPrice / o.count}`;
    notes.push(`${title} ${price}`);
  });

  return notes.join('\n');
}
