import { configure } from 'mobx';
import React, { FC } from 'react';
import { IRootStore, RootStore } from './root.store';

export let rootStore: IRootStore;

const StoreContext = React.createContext<IRootStore | null>(null);

export const createStore = () => {
  configure({ enforceActions: 'observed' });
  return new RootStore();
};

export const StoreProvider: FC = ({ children }) => {
  rootStore = createStore();
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
};

// tslint:disable-next-line: only-arrow-functions
export const useStore = function<T = IRootStore>(selector?: (s: IRootStore) => T): T {
  const store = React.useContext(StoreContext);

  if (selector) {
    return selector(store);
  }

  return (store as unknown) as T;
};
