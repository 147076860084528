import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useStore } from '../../store';

const ContactInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  font-size: 20px;
`;

const ScannerModal: FC = observer(() => {
  let inputRef: HTMLInputElement;

  const rootStore = useStore();

  const onModalClose = () => {
    rootStore.vm.toggleScanModal(false);
  };

  const searchCustomer = async () => {
    try {
      if (inputRef?.value?.trim()) {
        await rootStore.reward.fetchUser(inputRef.value.trim());
        if (!rootStore.reward.error) {
          onModalClose();
        }
      }
    } catch (ex) {
      //
    }
  };

  const clearInput = () => {
    if (inputRef) {
      inputRef.value = '';
    }
  };

  const onSeachInput = (e) => {
    if (e.which === 13) {
      searchCustomer();
    }
  };

  return (
    <Dialog
      transitionDuration={{ enter: 0, exit: 0 }}
      open={rootStore.vm.isUserScanModalOpen}
      onClose={onModalClose}
    >
      {rootStore.reward.isFetching ? (
        <DialogContent>
          <Typography variant="h5" component="h5">
            Fetching customer...
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="h5" component="p">
            Scan customer's barcode or type in membership ID or email
          </Typography>
          <br />
          <br />
          {rootStore.reward.error && (
            <Typography variant="caption" component="p" color="error">
              {rootStore.reward.error}
            </Typography>
          )}
          <ContactInput
            type="email"
            ref={(r) => (inputRef = r)}
            autoFocus
            placeholder="Scan or type in customer's QR code or email"
            autoComplete="off"
            autoCorrect="none"
            onKeyPress={onSeachInput}
          />
          <Button color="primary" onClick={clearInput}>
            Clear
          </Button>
          <Button color="primary" variant="contained" onClick={searchCustomer}>
            Find Customer
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
});

export default ScannerModal;
