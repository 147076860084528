import React, { FC, memo } from 'react';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { flexLayout } from '../../lib/mixins';
import { IOnlineOrder, OnlineOrderStatus } from '@markocen/bubblenation-base/models';
import { getVendorText } from '../../services/onilne-order.service';

const CardContainer = styled<any>(Card)`
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border-bottom: 1px solid green;
  border-radius: 0;
  overflow: initial !important;
`;

interface IProps {
  order: IOnlineOrder;
  onClick(orderId: string): void;
}

const getStatusText = (status: OnlineOrderStatus): { text: string; color: string } => {
  switch (status) {
    case OnlineOrderStatus.Parsed: {
      return { text: 'NEW', color: 'red' };
    }
    case OnlineOrderStatus.Doing: {
      return { text: 'DOING', color: 'orange' };
    }
    case OnlineOrderStatus.Finish: {
      return { text: 'DONE', color: 'green' };
    }
    case OnlineOrderStatus.Cancel: {
      return { text: 'CANCELED', color: 'gray' };
    }
  }
};

const TitleLine = styled.div`
  padding-right: 15px;
  ${flexLayout({ justifyContent: 'space-between', alignItems: 'center' })}
`;

const getDateString = (timestamp: number) => {
  const d = new Date(timestamp);
  return `${d.toLocaleTimeString()} ${d.toLocaleDateString()}`;
};

const OnlineOrderCard: FC<IProps> = memo(({ onClick, order }) => {
  const { text, color } = getStatusText(order.status);
  const vendorText = getVendorText(order.vendor);

  return (
    <CardContainer>
      <CardActionArea onClick={() => onClick(order._id)}>
        <CardContent>
          <TitleLine>
            <Typography variant="subtitle1" component="h4">
              {order.name}
            </Typography>
            <Chip variant="outlined" label={text} style={{ borderColor: color, color }} />
          </TitleLine>
          <Typography
            variant="subtitle2"
            component="p"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {vendorText.text && (
              <Chip
                size="small"
                variant="outlined"
                label={vendorText.text}
                style={{
                  borderColor: vendorText.color,
                  color: vendorText.color,
                  marginRight: 5,
                }}
              />
            )}
            Pickup @ {getDateString(Number(order.pickupTime))}
          </Typography>
        </CardContent>
      </CardActionArea>
    </CardContainer>
  );
});

export default OnlineOrderCard;
