import React, { FC, memo } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IProps {
  onChange(type: 'new' | 'completed'): void;
}

const OrderStatusSelector: FC<IProps> = memo(({ onChange }) => {
  return (
    <RadioGroup style={{ height: 45 }} row name="position" defaultValue="new">
      <FormControlLabel
        value="new"
        onChange={() => onChange('new')}
        control={<Radio color="primary" />}
        label="New Orders"
        labelPlacement="end"
      />
      <FormControlLabel
        value="completed"
        onChange={() => onChange('completed')}
        control={<Radio color="primary" />}
        label="Completed Orders"
        labelPlacement="end"
      />
    </RadioGroup>
  );
});

export default OrderStatusSelector;
