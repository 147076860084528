import Drawer from '@material-ui/core/Drawer';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import TransactionHistoryCard from './TransactionHistoryCard';
import TransactionModal from './TransactionModal';
import { useStore } from '../../store';

const drawerWidth = '40vw';

const styles = (theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    height: '100%',
    flexShrink: 0,
    overflow: 'auto',
  },
  drawerPaper: {
    width: drawerWidth,
    padding: '20px 10px',
  },
  sectionTitle: {
    width: '100%',
    padding: '5px 10px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
});

interface IProps extends StyledComponentProps {
  isOpen: boolean;
}

const TransactionHistoryDrawer: FC<IProps> = observer(({ isOpen, classes }) => {
  const rootStore = useStore();

  const onDrawerScroll = (e) => {
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight > target.scrollHeight - 50 &&
      !rootStore.transaction.isFetchingTransaction
    ) {
      rootStore.transaction.loadTransactions();
    }
  };

  const onTransactionClick = (transactionId: string) =>
    rootStore.transaction.openTransactionModal(transactionId);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={isOpen}
      onScroll={onDrawerScroll}
    >
      <Typography variant="h5" component="h5" className={classes.sectionTitle}>
        Transaction History:
      </Typography>

      {rootStore.transaction.transactionHistory.map((t) => (
        <TransactionHistoryCard key={t._id} {...t} onClick={onTransactionClick} />
      ))}

      <TransactionModal />
    </Drawer>
  );
});

export default withStyles(styles)(TransactionHistoryDrawer);
