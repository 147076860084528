import { post, put } from '../lib/ajax';
import {
  APIs,
  IModifier,
  IOnlineOrder,
  ITopping,
  ITransaction,
  OnlineOrderStatus,
} from '@markocen/bubblenation-base/models';
import { guid } from '../lib/utils';
import { IOrderDetail } from '../models/checkout.model';

export const refundOrder = async (opts: {
  reason: string;
  orderId: string;
  locationId: string;
}) => {
  return post(APIs.square.refund, opts);
};

export const markOrderAsCanceled = async (opts: {
  type: 'square' | 'emailOrder';
  storeId: string;
  locationId: string;
  orderId: string;
}) => {
  if (opts.type === 'square') {
    return put(APIs.square.order, {
      ...opts,
      state: OnlineOrderStatus.Cancel,
    });
  } else {
    return put(APIs.emailOrder.order, { orderId: opts.orderId, status: OnlineOrderStatus.Cancel });
  }
};

export const markOrderAsConfirm = async (opts: {
  type: 'square' | 'emailOrder';
  orderId: string;
  storeId: string;
  locationId: string;
}) => {
  if (opts.type === 'square') {
    return put(APIs.square.order, {
      ...opts,
      state: OnlineOrderStatus.Doing,
    });
  } else {
    return put(APIs.emailOrder.order, { orderId: opts.orderId, status: OnlineOrderStatus.Doing });
  }
};

export const markOrderAsReady = async (opts: {
  type: 'square' | 'emailOrder';
  orderId: string;
  storeId: string;
  locationId: string;
}) => {
  if (opts.type === 'square') {
    return put(APIs.square.order, {
      ...opts,
      state: OnlineOrderStatus.Finish,
    });
  } else {
    return put(APIs.emailOrder.order, {
      orderId: opts.orderId,
      status: OnlineOrderStatus.Finish,
    });
  }
};

export const fetchActiveOrders = async (opt: {
  storeId: string;
  locationId: string;
}): Promise<IOnlineOrder[]> => {
  const squareOrders = await post(APIs.square.activeOrders, opt);
  const emailOrders = await post(APIs.emailOrder.activeOrders, { storeId: opt.storeId });
  return [...squareOrders, ...emailOrders];
};

export const fetchTodayCompletedOrders = async (opt: {
  storeId: string;
  locationId: string;
  squareCursor: string;
  emailOrderCursor: number;
}): Promise<{ orders: IOnlineOrder[]; squareCursor: string; emailOrderCursor: number }> => {
  const completedSquareOrders = await post(APIs.square.completedOrders, {
    cursor: opt.squareCursor,
    locationId: opt.locationId,
    storeId: opt.storeId,
  });
  const completedEmailOrders = await post(APIs.emailOrder.completedOrders, {
    cursor: opt.emailOrderCursor,
    storeId: opt.storeId,
  });
  return {
    orders: [...completedEmailOrders.orders, ...completedSquareOrders.orders],
    squareCursor: completedSquareOrders.cursor,
    emailOrderCursor: completedEmailOrders.cursor,
  };
};

export const parseSquareMoneyAmount = (amount: number): number => {
  return Number((amount / 100).toFixed(2));
};

const patchOrderDetailFromModifier = (
  detail: IOrderDetail,
  name: string,
  modifiers: IModifier[],
  toppings: ITopping[],
): void => {
  modifiers.forEach((mod) => {
    mod.options.forEach((option) => {
      if (option.label.toLowerCase() === name.toLowerCase()) {
        if (option.value !== mod.default) {
          detail.modifiers.push({ key: mod._id, value: option.value });
        }
      }
    });
  });

  toppings.forEach((top) => {
    if (top.name.toLowerCase() === name.toLowerCase()) {
      detail.extraToppings.push({ ...top });
    }
  });

  if (name.toLowerCase().startsWith('extra topping')) {
    detail.extraToppings.push({
      _id: 'CLASSIC-BUBBLE',
      name: 'Classic Bubble',
      price: 0.5,
    });
  }
};

export const mapOnlineOrderToOrderDetail = (opts: {
  order: IOnlineOrder;
  modifiers: IModifier[];
  toppings: ITopping[];
}): IOrderDetail[] => {
  const details: IOrderDetail[] = [];
  const { order } = opts;

  order.items.forEach((item) => {
    const detail: IOrderDetail = {
      _id: item._id,
      orderId: guid(),
      count: Number(item.count),
      size: 'large',
      totalPrice: 0,
      noDefaultToppings: [],
      extraToppings: [],
      modifiers: [],
      note: '',
    };

    item.tags.forEach((tag) => {
      patchOrderDetailFromModifier(detail, tag, opts.modifiers, opts.toppings);
    });

    details.push(detail);
  });

  return details;
};

export const mapOnlineOrderToTransaction = (opts: {
  order: IOnlineOrder;
  cashierId: string;
  locationId: string;
}): ITransaction => {
  const { order, cashierId, locationId } = opts;

  return {
    _id: guid(),
    timestamp: order.timestamp,
    cashierId,
    locationId,
    posId: order._id,
    orderType: 'online',
    onlineOrderSummary: {
      orderId: order.vendorOrderId,
      pickupTime: order.pickupTime,
      pickupStore: locationId,
      name: order.name,
      email: order.email,
      phone: order.phone,
      payment: [],
    },
    note: order.note,
    tax: order.tax,
    total: order.total,
    subtotal: order.subtotal,
    items: order.items,
  };
};

export const getVendorText = (vendor: IOnlineOrder['vendor']): { text: string; color: string } => {
  switch (vendor) {
    case 'uber':
      return { text: 'UBER', color: '#5fb70b' };
    case 'square':
      return { text: '', color: '' };
    case 'doordash':
      return { text: 'DOORD', color: '#e9970f' };
    case 'ricepo':
      return { text: 'RICEPO', color: 'black' };
    case 'grubhub':
      return { text: 'GRUBHUB', color: '#f43e4d' };
    case 'caviar':
      return { text: 'CAVIAR', color: '#f86932' };
  }
};
