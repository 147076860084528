import { action, makeObservable, observable } from 'mobx';
import { IRootStore, RootStore } from './root.store';

export interface IViewModelStore {
  isCheckoutPanelOpen: boolean;
  isTransactionHistoryPanelOpen: boolean;
  isTransactionModalOpen: boolean;
  isSignInModalOpen: boolean;
  isOrderModalOpen: boolean;
  isUserScanModalOpen: boolean;
  isExternalCheckout: boolean;
  isPrintingLabel: boolean;
  isPromotionModalOpen: boolean;
  isSettingsModalOpen: boolean;
  isOnlineOrderPanelOpen: boolean;
  isOnlineOrderModalOpen: boolean;
  isInventoryAlertModalOpen: boolean;
  toggleCheckoutPanel(isOpen: boolean): void;
  toggleTransactionHistoryPanel(isOpen: boolean): void;
  toggleTransactionModal(isOpen: boolean): void;
  toggleSignInModal(isOpen: boolean): void;
  toggleOrderModal(isOpen: boolean): void;
  toggleScanModal(isOpen: boolean): void;
  toggleExternalCheckout(isCheckout: boolean): void;
  togglePromotionModal(isOpen: boolean): void;
  togglePrintingLabel(isPrinting: boolean): void;
  toggleSettingsModal(isOpen: boolean): void;
  toggleOnlineOrderPanel(isOpen: boolean): void;
  toggleOnlineOrderModal(isOpen: boolean): void;
  toggleInventoryAlertModal(isOpen: boolean): void;
}

export class ViewModelStore implements IViewModelStore {
  root: IRootStore;

  @observable isCheckoutPanelOpen = false;
  @observable isTransactionHistoryPanelOpen = false;
  @observable isTransactionModalOpen = false;
  @observable isSignInModalOpen = true;
  @observable isOrderModalOpen = false;
  @observable isUserScanModalOpen = false;
  @observable isExternalCheckout = false;
  @observable isPrintingLabel = false;
  @observable isPromotionModalOpen = false;
  @observable isSettingsModalOpen = false;
  @observable isOnlineOrderPanelOpen = false;
  @observable isOnlineOrderModalOpen = false;
  @observable isInventoryAlertModalOpen = false;

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this);
  }

  @action
  toggleCheckoutPanel = (isOpen: boolean) => {
    this.isCheckoutPanelOpen = isOpen;
  };

  @action
  toggleTransactionHistoryPanel = (isOpen: boolean) => {
    this.isTransactionHistoryPanelOpen = isOpen;
  };

  @action
  toggleTransactionModal = (isOpen: boolean) => {
    this.isTransactionModalOpen = isOpen;
  };

  @action
  toggleSignInModal = (isOpen: boolean) => {
    this.isSignInModalOpen = isOpen;
  };

  @action
  toggleOrderModal = (isOpen: boolean) => {
    this.isOrderModalOpen = isOpen;
  };

  @action
  toggleScanModal = (isOpen: boolean) => {
    this.isUserScanModalOpen = isOpen;
  };

  @action
  toggleExternalCheckout = (isCheckout: boolean) => {
    this.isExternalCheckout = isCheckout;
  };

  @action
  togglePrintingLabel = (isPrinting: boolean) => {
    this.isPrintingLabel = isPrinting;
  };

  @action
  togglePromotionModal = (isOpen: boolean) => {
    this.isPromotionModalOpen = isOpen;
  };

  @action
  toggleSettingsModal = (isOpen: boolean) => {
    this.isSettingsModalOpen = isOpen;
  };

  @action
  toggleOnlineOrderPanel = (isOpen: boolean): void => {
    this.isOnlineOrderPanelOpen = isOpen;
  };

  @action
  toggleOnlineOrderModal = (isOpen: boolean): void => {
    this.isOnlineOrderModalOpen = isOpen;
  };

  @action
  toggleInventoryAlertModal = (isOpen: boolean): void => {
    this.isInventoryAlertModalOpen = isOpen;
  };
}
