import { get, post } from '../lib/ajax';
import { APIs, IPublicUser } from '@markocen/bubblenation-base/models';

interface IFetchUserResponse {
  user: IPublicUser;
  points: number;
  redeemable: number;
  pointToMoneyRate: number;
  moneyToPointRate: number;
}

export async function fetchPublicUser(customerId: string): Promise<IPublicUser> {
  return get<IPublicUser>(APIs.pos.user, { customerId });
}

export async function fetchDetailUser(contact: string): Promise<IFetchUserResponse> {
  return post<IFetchUserResponse>(APIs.pos.user, { contact });
}
