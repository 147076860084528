import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';
import { useStore } from '../../store/index';

const RewardContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const RewardActionsContainer = styled.div`
  ${flexLayout({ justifyContent: 'flex-end' })}
  margin-top: 0.25rem;
  button {
    margin-right: 0.35rem;
  }
`;

const RewardCard: FC = observer(() => {
  const rootStore = useStore();
  const rewardStore = useStore((s) => s.reward);

  const {
    user,
    points,
    clearUser,
    redeemable,
    pointToMoneyRate,
    redeemReward,
    resetRedeem,
  } = rewardStore;

  return (
    <RewardContainer>
      <Typography variant="h5" component="h5">
        Email: {user.email}
      </Typography>
      <Typography variant="h5" component="h5">
        Total Points: {points}
      </Typography>
      <Typography variant="h5" component="h5">
        Redeemable: {redeemable} (${pointToMoneyRate * redeemable} off)
      </Typography>

      {rootStore.promotion.selectedPromotion && (
        <Typography variant="subtitle1" component="h5">
          Can't use reward points, promotion applied
        </Typography>
      )}

      <RewardActionsContainer>
        <Button variant="contained" color="secondary" onClick={clearUser}>
          Clear User
        </Button>
        <Button variant="contained" color="secondary" onClick={resetRedeem}>
          Reset Redeem
        </Button>

        {!rootStore.promotion.selectedPromotion && (
          <Button
            variant="contained"
            color="primary"
            disabled={redeemable <= 0}
            onClick={redeemReward}
          >
            Redeem
          </Button>
        )}
      </RewardActionsContainer>
    </RewardContainer>
  );
});

export default RewardCard;
