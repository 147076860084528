import { get } from '../lib/ajax';
import { IMenuItem, APIs, ITopping, IModifier } from '@markocen/bubblenation-base/models';

export async function fetchMenu() {
  const menus = await get<IMenuItem[]>(APIs.menu.menus);
  return (menus || []).sort((a, b) => (a.code < b.code ? -1 : 1));
}

export async function fetchTopping() {
  return get<ITopping[]>(APIs.menu.toppings);
}

export async function fetchMenuWording(lang = 'en') {
  return get(APIs.locale.langAndNs(lang, 'menu'));
}

export async function fetchModifier() {
  return get<IModifier[]>(APIs.menu.modifiers);
}
