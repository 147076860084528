import React, { FC, memo } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { ITopping } from '@markocen/bubblenation-base/models';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 110,
  },
}));

interface IProps {
  readonly options: ITopping[];
  values: ITopping[];
  onSelect(value: ITopping, count: number): void;
}

const OrderExtraToppingCard: FC<IProps> = memo(({ options, values, onSelect }) => {
  const classes = useStyles();

  const getValue = (id: ITopping['_id']): number => {
    return values.filter((v) => v._id === id).length;
  };

  const onMenuItemSelected = (event, topping: ITopping) => {
    const count = event.target.value;
    onSelect(topping, count);
  };

  return (
    <FormGroup row>
      {options.map((option) => (
        <FormControl key={option._id} className={classes.formControl}>
          <InputLabel shrink id={`input-label-for-extra-topping-${option.name}`}>
            {option.name}
          </InputLabel>
          <Select
            displayEmpty
            value={getValue(option._id)}
            onChange={(e) => onMenuItemSelected(e, option)}
            labelId={`input-label-for-extra-topping-${option.name}`}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
      ))}
    </FormGroup>
  );
});

export default OrderExtraToppingCard;
