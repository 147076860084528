import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import Loyalty from '@material-ui/icons/Loyalty';
import Print from '@material-ui/icons/Print';
import UserIcon from '@material-ui/icons/SettingsRemote';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';
import { printLabel } from '../../services/printer.service';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { OrderCard } from './OrderCard';
import OrderSummaryCard from './OrderSummaryCard';
import PromotionSelectionModal from './PromotionSelectionModal';
import RewardCard from './RewardCard';
import { useStore } from '../../store';

const RewardContainer = styled.div`
  width: 100%;
  ${flexLayout({ direction: 'column' })}
  flex-shrink: 0;
  margin-bottom: 15px;
`;

const PromotionContainer = styled.div`
  width: 100%;
  ${flexLayout({ direction: 'column' })}
  flex-shrink: 0;
  margin-bottom: 15px;
`;

const OrderListContainer = styled.div`
  width: 100%;
  ${flexLayout({ direction: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' })}
  flex-shrink: 0;
  margin-bottom: 15px;
`;

const OrderActionsContainer = styled.div`
  width: 100%;
  ${flexLayout({ direction: 'column', justifyContent: 'flex-end' })}
  flex-shrink: 0;
  button {
    margin-bottom: 0.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const drawerWidth = '40vw';

const styles = (theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    height: '100%',
    flexShrink: 0,
    overflow: 'auto',
  },
  drawerPaper: {
    width: drawerWidth,
    padding: '20px 10px',
  },
  sectionTitle: {
    width: '100%',
    padding: '5px 10px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
});

interface IProps extends StyledComponentProps {
  isOpen: boolean;
}

const CheckoutDrawer: FC<IProps> = observer(({ isOpen, classes }) => {
  const [isCancelConfirmModalOpen, setCancelConfirmModalOpen] = useState(false);
  const rootStore = useStore();
  const { orders } = rootStore.checkout;

  const toggleCancelConfirmModal = () => setCancelConfirmModalOpen(!isCancelConfirmModalOpen);

  const printLabelsOnly = async () => {
    try {
      if (orders.length > 0) {
        rootStore.vm.togglePrintingLabel(true);
        await printLabel(undefined, orders);
        rootStore.vm.togglePrintingLabel(false);
      }
    } catch (ex) {
      rootStore.vm.togglePrintingLabel(false);
      alert(ex);
    }
  };

  const cancelCheckout = () => {
    setCancelConfirmModalOpen(false);
    rootStore.checkout.clearCheckout();
  };

  return (
    <Drawer
      className={classes!.drawer}
      variant="persistent"
      anchor="right"
      classes={{
        paper: classes!.drawerPaper,
      }}
      open={isOpen}
    >
      {rootStore.reward.user && (
        <RewardContainer>
          <Typography variant="h5" component="h5" className={classes!.sectionTitle}>
            Customer:
          </Typography>
          <RewardCard />
        </RewardContainer>
      )}

      {rootStore.promotion.selectedPromotion && (
        <PromotionContainer>
          <Typography variant="h5" component="h5" className={classes!.sectionTitle}>
            Applied Promotion:
          </Typography>
          <Typography variant="h6" component="h6">
            {rootStore.promotion.selectedPromotion.name}
          </Typography>
        </PromotionContainer>
      )}

      <OrderListContainer>
        <Typography variant="h5" component="h5" className={classes!.sectionTitle}>
          Items:
        </Typography>
        {rootStore.checkout.orders.length <= 0 && (
          <Typography variant="subtitle1" component="h5">
            No item need to checkout
          </Typography>
        )}
        {rootStore.checkout.orders.map((order) => (
          <OrderCard
            key={order.orderId}
            order={order}
            wording={rootStore.menu.wordings[order._id]}
            onRemove={rootStore.checkout.removeOrder}
          />
        ))}
      </OrderListContainer>
      {rootStore.checkout.orders.length > 0 && <OrderSummaryCard />}
      {rootStore.checkout.orders.length > 0 && (
        <OrderActionsContainer>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={rootStore.checkout.goCheckout}
          >
            <MoneyIcon />
            &nbsp;&nbsp;Checkout
          </Button>
          {!rootStore.reward.user && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => rootStore.vm.toggleScanModal(true)}
            >
              <UserIcon />
              &nbsp;&nbsp;Reward Program
            </Button>
          )}
          <Button fullWidth variant="contained" color="primary" onClick={printLabelsOnly}>
            <Print />
            &nbsp;&nbsp;Print Labels Only
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={toggleCancelConfirmModal}
          >
            Cancel Order
          </Button>
        </OrderActionsContainer>
      )}

      <ConfirmModal
        isOpen={isCancelConfirmModalOpen}
        title="Are you sure you want to cancel the order?"
        onCancel={toggleCancelConfirmModal}
        onConfirm={cancelCheckout}
      />

      <PromotionSelectionModal />
    </Drawer>
  );
});

export default withStyles(styles)(CheckoutDrawer);
