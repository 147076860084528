import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ItemCard } from './ItemCard';
import { ItemFilter } from './ItemFilter';
import ItemOrderModal from '../../components/ItemOrderModal/ItemOrderModal';
import { flexLayout } from '../../lib/mixins';
import { useStore } from '../../store';
import { IMenuItem } from '@markocen/bubblenation-base/models';

const MenuContainer = styled.div`
  padding: 80px 15px;
`;

const ItemGrid = styled<any>(Grid)`
  ${flexLayout({ alignItems: 'stretch' })}
`;

const Menu: FC = observer(() => {
  const rootStore = useStore();

  useEffect(() => {
    rootStore.menu.refreshMenu();
    rootStore.onlineOrder.startFetchActiveOrders();
    return () => {
      rootStore.onlineOrder.stopFetchActiveOrders();
    };
  }, []);

  const onItemCardClick = (item: IMenuItem) => {
    rootStore.menu.setSelectedItem(item);
    rootStore.vm.toggleOrderModal(true);
  };

  const onFilterClick = (category: string) => rootStore.menu.setSelectedFilter(category);

  const { menu, wordings, chineseWordings, categories, selectedFilter } = rootStore.menu;
  const allCategories = ['All', ...categories];

  return (
    <MenuContainer>
      <ItemOrderModal />
      <ItemFilter categories={allCategories} onChange={onFilterClick} />
      <Grid container spacing={2} alignItems="stretch">
        {(menu || [])
          .filter((m) => (selectedFilter === 'All' ? true : m.category === selectedFilter))
          .filter((m) => m.isPosVisible)
          .map((m) => (
            <ItemGrid
              item
              xs={4}
              sm={4}
              md={rootStore.vm.isCheckoutPanelOpen ? 4 : 3}
              lg={2}
              key={m._id}
              style={{ display: 'flex', alignItems: 'stretch' }}
            >
              <ItemCard
                item={m}
                wording={wordings[m._id]}
                chineseWording={chineseWordings[m._id]}
                onClick={onItemCardClick}
              />
            </ItemGrid>
          ))}
      </Grid>
    </MenuContainer>
  );
});

export default Menu;
