import { action, makeObservable, observable } from 'mobx';
import { IOrderDetail } from '../models/checkout.model';
import { IPromotion, IPromotionOption } from '../models/promotion.model';
import { IRootStore, RootStore } from './root.store';

export interface IPromotionStore {
  promotions: IPromotionOption[];
  selectedPromotion?: IPromotionOption;
  setPromotion(promotionId: string): void;
  runPromotion(): IPromotion | undefined;
  clearPromotion(): void;
}

export class PromotionStore implements IPromotionStore {
  rootStore: IRootStore;

  promotions = [
    {
      id: 'BUY_ONE_GET_ONE_FREE',
      name: 'Buy One Get One Free Voucher',
      callback: this.buyOneGetOneFree,
    },
    { id: 'ALL_FREE', name: 'All Free Voucher', callback: this.allFree },
  ];

  @observable selectedPromotion: IPromotionOption | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action
  setPromotion(promotionId: string) {
    const promotion = this.promotions.filter((p) => p.id === promotionId)[0];
    if (promotion) {
      this.selectedPromotion = promotion;
    }
  }

  @action
  runPromotion() {
    if (this.selectedPromotion) {
      return this.selectedPromotion.callback();
    }
  }

  @action
  clearPromotion() {
    this.selectedPromotion = undefined;
  }

  private buyOneGetOneFree(orders: IOrderDetail[]): IPromotion {
    const items: { totalPrice: number }[] = [];
    orders.forEach((o) => {
      for (let i = 0; i < o.count; i++) {
        items.push({ totalPrice: o.totalPrice / o.count });
      }
    });

    const cheapest = items.sort((a, b) => a.totalPrice - b.totalPrice)[0];
    const discount = cheapest && items.length > 1 ? cheapest.totalPrice : 0;

    return {
      name: 'Buy One Get One Free',
      note: '',
      discount,
    };
  }

  private allFree(orders: IOrderDetail[]): IPromotion {
    const discount = orders.reduce((pre, curr) => pre + curr.totalPrice, 0);

    return {
      name: 'All Free',
      note: '',
      discount,
    };
  }
}
