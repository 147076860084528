import { action, computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { SQUARE_UP_APP_ID, SQUARE_UP_APP_VRESION, SQUARE_UP_CALLBACK_URL } from '../lib/consts';
import { guid } from '../lib/utils';
import { IOrderDetail } from '../models/checkout.model';
import { printLabel, printReceipt } from '../services/printer.service';
import { IRootStore, RootStore } from './root.store';
import { ITransaction } from '@markocen/bubblenation-base/models';

export interface ICheckoutStore {
  rootStore: IRootStore;
  orders: IOrderDetail[];
  enableLabelPrinting: boolean;
  readonly subtotal: number;
  readonly discount: number;
  readonly promoDiscount: number;
  readonly tax: number;
  readonly total: number;
  readonly pointsToGain: number;
  addOrder(order: IOrderDetail): void;
  removeOrder(orderId: string): void;
  goCheckout(): void;
  clearCheckout(): void;
  setEnableLabelPrinting(enabled: boolean): void;
}

export class CheckoutStore implements ICheckoutStore {
  @computed get subtotal() {
    return this.orders.map((o) => o.totalPrice).reduce((pre, curr) => pre + curr, 0);
  }

  @computed get discount() {
    return this.rootStore.reward.user
      ? this.rootStore.reward.pointsToRedeem * this.rootStore.reward.pointToMoneyRate
      : 0;
  }

  @computed get promoDiscount() {
    if (!this.rootStore.promotion.selectedPromotion) {
      return 0;
    }
    return this.rootStore.promotion.selectedPromotion.callback(this.orders).discount;
  }

  @computed get tax() {
    return 0.07 * (this.subtotal - this.discount - this.promoDiscount);
  }

  @computed get pointsToGain() {
    // can't gain points if promotion applied
    if (this.rootStore.promotion.selectedPromotion) {
      return 0;
    }

    return (this.subtotal - this.discount) * this.rootStore.reward.moneyToPointRate;
  }

  @computed get total() {
    return this.subtotal - this.discount - this.promoDiscount + this.tax;
  }
  rootStore: IRootStore;

  @observable orders: IOrderDetail[] = [];
  @persist @observable enableLabelPrinting: boolean = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.listenOnOrderCompleted();
  }

  @action
  setEnableLabelPrinting = (enabled: boolean) => {
    this.enableLabelPrinting = enabled;
  };

  @action
  addOrder = (order: IOrderDetail) => {
    this.orders = [...this.orders, order];
    this.rootStore.vm.toggleOnlineOrderPanel(false);
    this.rootStore.vm.toggleTransactionHistoryPanel(false);
    this.rootStore.vm.toggleCheckoutPanel(true);
  };

  @action
  removeOrder = (orderId: string) => {
    const index = this.orders.map((o) => o.orderId).indexOf(orderId);
    if (index > -1) {
      this.orders.splice(index, 1);
      this.orders = [...this.orders];
    }
  };

  @action
  clearCheckout = () => {
    this.orders = [];
  };

  @action
  goCheckout = async () => {
    // amount could be 7.5, 7.50, 7.05, 7.15, 70.5, 70.50, 70.05, 17.5, 17.50, 17.05
    const amount = this.total - this.tax;
    // get the integer part of the amount, which is 7 or 70 or 17
    const intPart = Math.trunc(amount);
    // get digit part of the amount, which is 50, 5 or 15
    const digitPart = Math.round((amount - intPart) * 100);
    const params = {
      amount_money: {
        amount: `${intPart > 0 ? intPart : ''}${
          digitPart >= 10
            ? digitPart.toString().padEnd(2, '0')
            : digitPart.toString().padStart(2, '0')
        }`,
        currency_code: 'USD',
      },
      callback_url: SQUARE_UP_CALLBACK_URL,
      client_id: SQUARE_UP_APP_ID,
      version: SQUARE_UP_APP_VRESION,
      state: this.getState(),
      notes: printReceipt(this.orders, this.rootStore.menu.wordings),
      options: {
        auto_return: true,
        supported_tender_types: ['CREDIT_CARD', 'CASH', 'OTHER', 'SQUARE_GIFT_CARD'],
      },
    };

    setTimeout(() => this.openSquareUp(params), 100);
  };

  private getState = () => {
    return JSON.stringify({
      transaction: this.getTransactionDetail(),
      reward: this.getRewardDetail(),
    });
  };

  private getRewardDetail = () => {
    return this.rootStore.reward.user && this.rootStore.reward.user._id
      ? {
          customerId: this.rootStore.reward.user._id,
          addPoints: this.pointsToGain,
          redeemPoints: this.rootStore.reward.pointsToRedeem,
        }
      : null;
  };

  private getTransactionDetail = (): ITransaction => {
    const transaction: ITransaction = {
      _id: guid(),
      timestamp: Date.now(),
      cashierId: this.rootStore.session.employee._id || '',
      locationId: this.rootStore.session.location || '',
      posId: 'TBD',
      orderId: 'TBD',
      subtotal: this.subtotal,
      tax: this.tax,
      total: this.total,
      discount: this.discount,
      items: this.orders.map((o) => ({
        _id: o._id,
        count: o.count,
        tags: [
          o.note,
          ...o.modifiers.map((m) => m.value),
          o.noDefaultToppings.length > 0
            ? `No ${o.noDefaultToppings.map((t) => t._id).join(', ')}`
            : '',
          o.extraToppings.length > 0 ? `Extra ${o.extraToppings.map((t) => t._id).join(', ')}` : '',
        ].filter((n) => n),
      })),
    };

    if (this.rootStore.reward.user) {
      transaction.customerId = this.rootStore.reward.user._id;
      transaction.rewardPoints = this.pointsToGain;
    }

    if (this.rootStore.promotion.selectedPromotion) {
      transaction.promotion = {
        name: this.rootStore.promotion.selectedPromotion.id,
        discount: this.promoDiscount,
      };
    }

    return transaction;
  };

  private listenOnOrderCompleted = () => {
    window.addEventListener('storage', async (event: StorageEvent) => {
      if (event.key === 'order_completed' && event.newValue) {
        const { error_code, transaction_id, ticket_name } = JSON.parse(event.newValue || '{}');

        if (error_code) {
          alert(error_code);
        } else {
          try {
            if (this.enableLabelPrinting) {
              this.rootStore.vm.togglePrintingLabel(true);
              await printLabel(ticket_name, this.orders);
            }
          } catch (e) {
            //
          } finally {
            this.rootStore.vm.togglePrintingLabel(false);
            this.rootStore.vm.toggleCheckoutPanel(false);
            this.rootStore.reward.clearUser();
            this.rootStore.promotion.clearPromotion();
            this.clearCheckout();
          }
        }
      }
    });
  };

  private openSquareUp(params: any) {
    // @ts-ignore
    window.location = `square-commerce-v1://payment/create?data=${encodeURIComponent(
      JSON.stringify(params),
    )}`;

    // TODO: remove test code
    // const data = {
    //   transaction_id: 'transaction123',
    //   client_transaction_id: '40',
    //   status: 'ok',
    //   state: this.getState(),
    // };
    // window.open(`http://localhost:3000/api${APIs.pos.postCheckout}?data=${JSON.stringify(data)}`);
  }
}
