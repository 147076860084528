import { action, makeObservable, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { fetchEmployeeByCode, getEmployeeInfo, logoutEmployee } from '../services/auth.service';
import { IRootStore, RootStore } from './root.store';

export interface ISessionStore {
  employee: { name: string; _id: string };
  location: string;
  storeLocationId: string;
  error: string;
  signIn(code: string, location: string, storeLocationId: string): void;
  signOut(): void;
  clearError(): void;
}

export class SessionStore implements ISessionStore {
  rootStore: IRootStore;

  @persist('object') @observable employee: { name: string; _id: string } = null;
  @persist @observable location: string = null;
  @persist @observable storeLocationId: string = null;
  @observable error: string = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.getEmployee();
  }

  private handleException = (ex) => {
    this.error = ex.code
      ? ex.code
      : ex.response
      ? ex.response.data && ex.response.data.code
        ? ex.response.data.code
        : ex.response.statusText || ex
      : ex.toString();
  };

  @action
  getEmployee = async () => {
    try {
      const employee = await getEmployeeInfo();
      runInAction(() => {
        this.employee = employee;
      });
    } catch (ex) {
      runInAction(() => this.handleException(ex));
    }
  };

  @action
  signIn = async (code: string, location: string, storeLocationId: string) => {
    try {
      const res = await fetchEmployeeByCode(code, location);
      runInAction(() => {
        this.employee = res.employee;
        this.location = location;
        this.storeLocationId = storeLocationId;
        this.error = null;
      });
    } catch (ex) {
      runInAction(() => this.handleException(ex));
    }
  };

  @action
  signOut = async () => {
    await logoutEmployee();
    runInAction(() => {
      this.employee = null;
      this.location = null;
      this.storeLocationId = null;
      this.error = null;
    });
  };

  @action
  clearError = () => {
    this.error = null;
  };
}
