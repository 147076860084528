import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';
import { useStore } from '../../store/index';

const SummaryContainer = styled.div`
  padding: 0 10px;
  margin-bottom: 15px;
  ${flexLayout({ direction: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' })};
  flex-shrink: 0;
`;

const OrderSummaryCard: FC = observer(() => {
  const rootStore = useStore();
  const { checkout, reward } = rootStore;

  return (
    <SummaryContainer>
      <Typography variant="subtitle1" component="p">
        Subtotal: ${checkout.subtotal.toFixed(2)}
      </Typography>
      <Typography variant="subtitle1" component="p">
        Reward Discount: ${checkout.discount.toFixed(2)}
      </Typography>
      <Typography variant="subtitle1" component="p">
        Promo Discount: ${checkout.promoDiscount.toFixed(2)}
      </Typography>
      <Typography variant="subtitle1" component="p">
        Tax: ${checkout.tax.toFixed(2)}
      </Typography>
      {reward.user && (
        <Typography variant="subtitle1" component="p">
          Points: +{checkout.pointsToGain}
        </Typography>
      )}
      <Typography variant="subtitle1" component="p">
        <b>Total: ${checkout.total.toFixed(2)}</b>
      </Typography>
    </SummaryContainer>
  );
});

export default OrderSummaryCard;
