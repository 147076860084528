import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Snackbar from '@material-ui/core/Snackbar';
import { useStore } from '../../store/index';
import { notifySound } from '../../lib/sound';

const alertStyle = {
  padding: '10px 20px',
  backgroundColor: '#f44336',
  width: '100%',
  height: '100%',
  fontSize: '0.9rem',
  fontWeight: 700,
  borderRadius: '4px',
  color: 'white',
};

const NewOrderIndicator: FC = observer(() => {
  const [timer, setTimer] = useState(null);
  const open = useStore((s) => s.onlineOrder.orders.length > 0 && !s.vm.isOnlineOrderPanelOpen);
  const shouldPlaySound = useStore(
    (s) => s.onlineOrder.newOrders.length > 0 && !s.vm.isOnlineOrderPanelOpen,
  );
  const openOnlineOrderDrawer = useStore((s) => s.vm.toggleOnlineOrderPanel);
  const playInterval = 1000 * 10;

  const playAudio = () => {
    if (shouldPlaySound) {
      if (!notifySound.src) {
        notifySound.src = 'new-order-sound.mp3';
      }
      notifySound.play();
    }
  };

  const startPlayAudio = () => {
    setTimer(setInterval(() => playAudio(), playInterval));
  };

  const stopPlayAudio = () => {
    notifySound.pause();
    notifySound.currentTime = 0;
    if (timer) {
      clearInterval(timer);
    }
  };

  return (
    <Snackbar
      open={open}
      onEnter={() => startPlayAudio()}
      onExit={() => stopPlayAudio()}
      onClick={() => openOnlineOrderDrawer(true)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      color="error"
    >
      <div style={alertStyle}>New Online Order!</div>
    </Snackbar>
  );
});

export default NewOrderIndicator;
