import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';
import { guid } from '../../lib/utils';
import { Counter } from './counter';
import { OrderModifierSelect } from './OrderModifierSelect';
import OrderToppingCard from './OrderToppingCard';
import { useStore } from '../../store';
import { ItemSize, ITopping, Categories } from '@markocen/bubblenation-base/models';
import { IOrderDetail } from '../../models/checkout.model';
import OrderExtraToppingCard from './OrderExtraToppingCard';

const SelectContainer = styled.div`
  ${flexLayout({ justifyContent: 'flex-start' })}
  margin-bottom: 10px;
`;

const ToppingContainer = styled.div`
  margin-top: 20px;
`;

const PriceLabel = styled.label`
  margin-right: 25px;
  font-size: 25px;
  font-weight: bold;
  min-width: 100px;
`;

const ItemOrderModal: FC = observer(() => {
  const rootStore = useStore();
  const selectedItem = rootStore.menu.selectedItem;
  const allModifiers = rootStore.menu.modifier;

  const [count, setCount] = useState(1);
  const [size, setSize] = useState<ItemSize>('large');
  const [defaultToppings, setDefaultToppings] = useState<ITopping[]>([]);
  const [extraToppings, setExtraToppings] = useState<ITopping[]>([]);
  const [note, setNote] = useState('');
  const [modifierSelects, setModifierSelects] = useState<{ [key: string]: string }>({});

  const withoutToppings = (): ITopping[] => {
    const selectedDefaultToppingIds = defaultToppings.map((d: ITopping) => d._id);
    const without = (selectedItem.toppings || []).filter(
      (d: ITopping) => selectedDefaultToppingIds.indexOf(d._id) < 0,
    );
    return [...without];
  };

  const toppingPrice = (): number => extraToppings.reduce((pre, cur) => pre + cur.price, 0);

  const modifierPrice = (): number => {
    return Object.keys(modifierSelects).reduce<number>((previousValue, currentValue) => {
      const modifier = allModifiers.find((m) => m._id === currentValue);
      if (modifier) {
        const option = modifier.options.find((o) => o.label === modifierSelects[currentValue]);
        if (option) {
          previousValue = previousValue + (option.price || 0);
        }
      }
      return previousValue;
    }, 0);
  };

  const withoutToppingPrice = (): number => {
    return withoutToppings().reduce((pre, cur) => pre + cur.price, 0);
  };

  const price = (): number => {
    if (!selectedItem) {
      return 0;
    }
    const index = selectedItem.prices.map((p) => p.size).indexOf(size);

    if (index < 0) {
      return 0;
    }

    return (
      selectedItem.prices[index].price + modifierPrice() + toppingPrice() - withoutToppingPrice()
    );
  };

  const resetOptions = () => {
    setCount(1);
    setSize('large');
    setDefaultToppings([...selectedItem.toppings]);
    setExtraToppings([]);
    setNote('');
    setModifierSelects({});
  };

  const onModalOpen = () => {
    if (selectedItem) {
      setSize(selectedItem.prices[0].size);

      if (selectedItem.toppings) {
        setDefaultToppings([...selectedItem.toppings]);
      }
    }
  };

  const onModalClose = () => {
    resetOptions();
    rootStore.vm.toggleOrderModal(false);
    document.body.style.overflow = 'visible';
  };

  const onSizeChange = ({ target }) => setSize(target.value);

  const onSelectTopping = (topping: ITopping, toppingCount: number) => {
    const newExtraToppings = [...extraToppings].filter((t) => t._id !== topping._id);
    for (let i = 0; i < toppingCount; i++) {
      newExtraToppings.push(topping);
    }
    setExtraToppings([...newExtraToppings]);
  };

  const onSelectDefaultTopping = (topping: ITopping) => {
    setDefaultToppings([...defaultToppings, topping]);
  };

  const onDeselectDefaultTopping = (topping: ITopping) => {
    const index = defaultToppings.map((t) => t._id).indexOf(topping._id);
    if (index > -1) {
      defaultToppings.splice(index, 1);
      setDefaultToppings([...defaultToppings]);
    }
  };

  const onModifierSelect = (modId: string, value: string) => {
    const modifier = allModifiers.find((m) => m._id === modId);

    if (modifier && modifier.default !== value) {
      const newModifierSelects = { ...modifierSelects };
      newModifierSelects[modId] = value;
      setModifierSelects(newModifierSelects);
    } else {
      const newModifierSelects = { ...modifierSelects };
      delete newModifierSelects[modId];
      setModifierSelects(newModifierSelects);
    }
  };

  const onNoteChange = ({ target }) => setNote(target.value);

  const addCount = () => setCount(count + 1);

  const minusCount = () => setCount(count - 1 > 1 ? count - 1 : 1);

  const addOrderAndCopy = () => addOrder();

  const addOrderAndClose = () => {
    addOrder();
    onModalClose();
  };

  const addOrder = () => {
    const order: IOrderDetail = {
      _id: selectedItem._id,
      orderId: guid(),
      size,
      count,
      modifiers: Object.keys(modifierSelects).map((k) => ({ key: k, value: modifierSelects[k] })),
      totalPrice: count * price(),
      noDefaultToppings: withoutToppings(),
      extraToppings,
      note: note.trim(),
    };
    rootStore.checkout.addOrder(order);
  };

  const totalPrice = selectedItem ? price() * count : 0;
  const itemWording = selectedItem ? rootStore.menu.wordings[selectedItem._id] : { name: '' };
  const showOptions = selectedItem ? selectedItem.category !== 'Bakery' : false;

  return (
    <Dialog
      maxWidth={false}
      PaperProps={{ variant: 'outlined' }}
      TransitionProps={{ appear: false, enter: false, exit: false }}
      open={rootStore.vm.isOrderModalOpen}
      onRendered={onModalOpen}
      onClose={onModalClose}
    >
      {selectedItem && (
        <>
          <DialogTitle>
            {itemWording && itemWording.name ? itemWording.name : selectedItem._id}
          </DialogTitle>
          <DialogContent style={{ padding: '0 1.5rem' }}>
            <SelectContainer>
              <Typography variant="h6" component="label">
                Price:&nbsp;&nbsp;
              </Typography>
              <PriceLabel>${totalPrice}</PriceLabel>
              <Counter count={count} add={addCount} minus={minusCount} />
            </SelectContainer>
            <SelectContainer>
              <Typography variant="h6" component="label">
                Size:&nbsp;&nbsp;
              </Typography>
              <RadioGroup row name="size" value={size} onChange={onSizeChange}>
                {selectedItem.prices.map((p) => (
                  <FormControlLabel
                    key={p.size}
                    value={p.size}
                    control={<Radio color="primary" />}
                    label={`${p.size[0].toUpperCase()}${p.size.slice(1)}`}
                    labelPlacement="end"
                  />
                ))}
              </RadioGroup>
            </SelectContainer>

            {showOptions && (
              <SelectContainer>
                <Typography variant="h6" component="label">
                  Options:&nbsp;&nbsp;
                </Typography>
                {selectedItem.modifiers.map((modId) => {
                  const modifier = allModifiers.find((m) => m._id === modId);

                  return modifier ? (
                    <OrderModifierSelect
                      key={modId}
                      title={modifier.name}
                      value={modifierSelects[modifier._id] || modifier.default}
                      options={modifier.options}
                      onSelect={({ target }) => onModifierSelect(modifier._id, target.value)}
                      inputProps={{
                        name: modifier.name,
                        id: modifier._id,
                      }}
                    />
                  ) : (
                    <></>
                  );
                })}
              </SelectContainer>
            )}
            {selectedItem.toppings && selectedItem.toppings.length > 0 && (
              <ToppingContainer>
                <Typography variant="h6" component="label">
                  Default Toppings:
                  <br />
                </Typography>
                <OrderToppingCard
                  options={selectedItem.toppings}
                  values={defaultToppings}
                  onSelect={onSelectDefaultTopping}
                  onDeselect={onDeselectDefaultTopping}
                />
              </ToppingContainer>
            )}
            {selectedItem.addons.find((a) => a === 'toppings') && (
              <ToppingContainer>
                <Typography variant="h6" component="label">
                  Extra Toppings:
                  <br />
                </Typography>
                <OrderExtraToppingCard
                  options={
                    selectedItem.category === Categories.ProteinShake
                      ? rootStore.menu.topping
                      : rootStore.menu.topping.filter((t) => t._id !== 'PROTEIN-POWDER')
                  }
                  values={extraToppings}
                  onSelect={onSelectTopping}
                />
              </ToppingContainer>
            )}
            <TextField
              label="Note"
              placeholder="Note here if customers ask anything else..."
              fullWidth
              value={note}
              onChange={onNoteChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button color="primary" onClick={resetOptions}>
          Reset
        </Button>
        <Button color="primary" onClick={addOrderAndCopy}>
          Add & Copy
        </Button>
        <Button color="primary" onClick={addOrderAndClose}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ItemOrderModal;
