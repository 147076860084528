import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import { useStore } from '../store';
import { observer } from 'mobx-react-lite';
import { del, get, post } from '../lib/ajax';
import { APIs } from '@markocen/bubblenation-base/models';

const InventoryTrackingModal = observer(() => {
  const rootStore = useStore();
  const open = rootStore.vm.isInventoryAlertModalOpen;
  const [inventoryAlerts, setInventoryAlerts] = useState<{ [key in string]: string }>({});

  const menuItems = rootStore.menu.menu;
  const wordings = rootStore.menu.wordings;
  const toppings = rootStore.menu.topping;
  const tableRows: { _id: string; name: string; outOfStock: boolean }[] = [];

  const closeModal = () => rootStore.vm.toggleInventoryAlertModal(false);

  const onModalOpen = async () => {
    const alerts = await get(APIs.inventory.alert, { storeId: rootStore.session.location });
    setInventoryAlerts(alerts);
  };

  const isItemOutOfStock = (itemId: string) => {
    if (inventoryAlerts[itemId]) {
      const now = new Date();
      const expiredAt = new Date(inventoryAlerts[itemId]);
      if (now > expiredAt) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  const setItemOutOfStock = async (itemId: string) => {
    await post(APIs.inventory.alert, { storeId: rootStore.session.location, itemId });
    onModalOpen();
  };

  const removeItemOutOfStock = async (itemId: string) => {
    await del(APIs.inventory.alert, { storeId: rootStore.session.location, itemId });
    onModalOpen();
  };

  menuItems.forEach((item) => {
    tableRows.push({
      _id: item._id,
      name: wordings[item._id] ? wordings[item._id].name : item._id,
      outOfStock: isItemOutOfStock(item._id),
    });
  });

  toppings.forEach((tp) => {
    tableRows.push({
      _id: tp._id,
      name: tp.name,
      outOfStock: isItemOutOfStock(tp._id),
    });
  });

  return (
    <Dialog
      transitionDuration={{ enter: 0, exit: 0 }}
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={closeModal}
      onEntered={onModalOpen}
    >
      <DialogTitle>Inventory Tracking</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Out of Stock?</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows
                .sort((a, b) => (a.outOfStock ? -1 : a.name > b.name ? 1 : -1))
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.outOfStock ? <Typography color="secondary">Yes</Typography> : ''}
                    </TableCell>
                    <TableCell>
                      {row.outOfStock ? (
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => removeItemOutOfStock(row._id)}
                        >
                          Available
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={() => setItemOutOfStock(row._id)}
                        >
                          Unvailable
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
});

export default InventoryTrackingModal;
