import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import React, { FC, memo } from 'react';
import { ITopping } from '@markocen/bubblenation-base/models';

interface IProps {
  options: ITopping[];
  values: ITopping[];
  onSelect(value: ITopping): void;
  onDeselect(value: ITopping): void;
}

const OrderToppingCard: FC<IProps> = memo(({ options, values, onSelect, onDeselect }) => {
  return (
    <FormGroup row>
      {options.map((o) => (
        <FormControlLabel
          key={o._id}
          control={
            <Checkbox
              color="primary"
              checked={values.map((v) => v._id).indexOf(o._id) > -1}
              onChange={(e) => {
                e.target.checked ? onSelect(o) : onDeselect(o);
              }}
              value={o._id}
            />
          }
          label={o.name}
        />
      ))}
    </FormGroup>
  );
});

export default OrderToppingCard;
