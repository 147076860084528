import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';
import { IOrderDetail } from '../../models/checkout.model';

const OrderCardContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  ${flexLayout({ direction: 'column', justifyContent: 'space-between' })}
`;

const OrderContent = styled.div`
  width: 100%;
  ${flexLayout({ direction: 'column', justifyContent: 'space-between' })}
`;

const OrderTitleWrap = styled.div`
  ${flexLayout({ justifyContent: 'space-between' })}
  width: 100%;
`;

const OrderActionWrap = styled.div`
  flex: 1;
  ${flexLayout({ justifyContent: 'flex-end' })}
`;

const ChipContainer = styled.div`
  width: 100%;
  ${flexLayout({ justifyContent: 'flex-start' })}
  flex-wrap: wrap;
`;

const ChipTag = styled<any>(Chip)`
  height: auto !important;
  margin-top: 3px;
  margin-right: 3px;
  span {
    white-space: pre-line !important;
  }
`;

const SizeIndicator = styled.span`
  padding: 2px 6px;
  background-color: #007849;
  text-align: center;
  color: white;
  font-weight: bold;
  margin-right: 0.5rem;
`;

const OrderDetailWrap = styled.div`
  width: 100%;
`;

interface IProps {
  order: IOrderDetail;
  wording?: { name: string };
  onRemove(orderId: string): void;
}

export const OrderCard: FC<IProps> = memo(({ order, onRemove, wording }) => {
  const getExtraToppingStr = () => {
    const hash = {};
    (order.extraToppings || []).forEach((t) => {
      if (!hash[t.name]) {
        hash[t.name] = 0;
      }
      hash[t.name] = hash[t.name] + 1;
    });
    return Object.keys(hash).reduce(
      (pre, curr) => `${pre} ${curr}${hash[curr] > 1 ? ` x ${hash[curr]}` : ''},`,
      'Extra ',
    );
  };

  return (
    <OrderCardContainer>
      <OrderContent>
        <OrderTitleWrap>
          <Typography variant="subtitle1" component="h4">
            <SizeIndicator>
              {order.size.slice(0, 1).toUpperCase()} x {order.count}
            </SizeIndicator>
            {wording?.name ?? order._id}
          </Typography>
          <OrderActionWrap>
            <Typography variant="h6" component="h4">
              ${order.totalPrice}
            </Typography>
            <CancelIcon style={{ color: '#007849' }} onClick={() => onRemove(order.orderId)} />
          </OrderActionWrap>
        </OrderTitleWrap>

        <OrderDetailWrap>
          <ChipContainer>
            {order.modifiers.map((mod) => (
              <ChipTag key={mod.key} label={mod.value} color="primary" />
            ))}
            {order.noDefaultToppings.length > 0 && (
              <ChipTag
                color="primary"
                label={`No ${order.noDefaultToppings.map((t) => t.name).join(', ')}`}
                variant="outlined"
              />
            )}
          </ChipContainer>

          <ChipContainer>
            {order.extraToppings.length > 0 && (
              <ChipTag color="secondary" label={getExtraToppingStr()} />
            )}
            {order.note && (
              <ChipContainer>
                <ChipTag color="default" label={order.note} />
              </ChipContainer>
            )}
          </ChipContainer>
        </OrderDetailWrap>
      </OrderContent>
    </OrderCardContainer>
  );
});
