import Axios, { AxiosRequestConfig } from 'axios';
import { API_ENDPOINT } from './consts';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const baseURL = API_ENDPOINT;

async function request<T>(method: Method, url: string, payload: any): Promise<T> {
  const config: AxiosRequestConfig = {
    url,
    method,
    baseURL,
    params: method === 'GET' || method === 'DELETE' ? payload : null,
    data: method === 'GET' || method === 'DELETE' ? null : payload,
    responseType: 'json',
  };
  const res = await Axios.request<T>(config);
  return res.data;
}

export function get<T = any>(url: string, payload = null): Promise<T> {
  return request<T>('GET', url, payload);
}

export function post<T = any>(url: string, payload = null): Promise<T> {
  return request<T>('POST', url, payload);
}

export function put<T = any>(url: string, payload = null): Promise<T> {
  return request<T>('PUT', url, payload);
}

export function del<T = any>(url: string, payload = null): Promise<T> {
  return request<T>('DELETE', url, payload);
}

export function patch<T = any>(url: string, payload = null): Promise<T> {
  return request<T>('PATCH', url, payload);
}
