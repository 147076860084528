import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useStore } from '../../store';
import { get } from '../../lib/ajax';
import { APIs, IStore } from '@markocen/bubblenation-base/models';

const styles = (theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

const SignInModal: FC<StyledComponentProps> = observer(({ classes }) => {
  const rootStore = useStore();
  const [code, setCode] = useState('');
  const [allStores, setAllStores] = useState<IStore[]>([]);
  const [selectedStore, setSelectedStore] = useState('');

  useEffect(() => {
    get(APIs.storeInfo.search).then((stores) => {
      setAllStores(stores);
      setSelectedStore(stores[0]._id);
    });
  }, []);

  const onCodeChange = ({ target }) => setCode(target.value.trim());

  const onStoreChange = ({ target }) => setSelectedStore(target.value.trim());

  const onSignInClick = () => {
    setCode('');
    const locationId = allStores.find((a) => a._id === selectedStore)?.locationId;
    if (locationId && selectedStore) {
      rootStore.session.signIn(code, selectedStore, locationId);
    }
  };

  return (
    <Dialog open={!rootStore.session.employee}>
      <DialogTitle>Sign In</DialogTitle>
      <DialogContent>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={3000}
          onClose={() => rootStore.session.clearError()}
          open={!!rootStore.session.error}
        >
          <SnackbarContent className={classes.error} message={<h3>{rootStore.session.error}</h3>} />
        </Snackbar>
        <Select
          value={selectedStore}
          onChange={onStoreChange}
          fullWidth
          inputProps={{
            name: 'age',
            id: 'age-simple',
          }}
        >
          {allStores.map((st) => (
            <MenuItem value={st._id} key={st._id}>
              <em>{st.name}</em>
            </MenuItem>
          ))}
        </Select>
        <TextField
          autoFocus
          margin="dense"
          id="code"
          label="Employee Code"
          type="text"
          fullWidth
          value={code}
          onChange={onCodeChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onSignInClick} color="primary">
          Sign-in
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default withStyles(styles)(SignInModal);
