export const SQUARE_UP_APP_ID =
  process.env.NODE_ENV === 'production'
    ? 'sq0idp-DtsK45Rf4ONaAHtJfqDsyw'
    : 'sandbox-sq0idb-p6_orbTUPEFdnOn638g1HQ';

export const SQUARE_UP_APP_VRESION = '1.3';

export const SQUARE_UP_CALLBACK_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://posnext.bubblenation.us/api/pos/post-checkout'
    : 'https://8efdb5ee.ngrok.io/api/pos/post-checkout';

export const API_ENDPOINT = '/api/';

export const MAX_ORDER_COUNT_PER_HOUR = 999;

export const LABEL_PRINTER_PORT = '8043';
export const LABEL_PRINTER_ADDRESS = '192.168.0.88';
export const LABEL_PRINTER_ID = 'local_printer';

export const RECEIPT_PRINTER_PORT = '8043';
export const RECEIPT_PRINTER_ADDRESS = '192.168.0.99';
export const RECEIPT_PRINTER_ID = 'local_printer';
