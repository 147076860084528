import { action, makeObservable, observable, runInAction } from 'mobx';
import { fetchDetailUser } from '../services/reward.service';
import { IRootStore, RootStore } from './root.store';
import { IPublicUser, IRewardHistory } from '@markocen/bubblenation-base/models';

export interface IRewardStore {
  user?: IPublicUser;
  redeemable: number;
  points: number;
  pointToMoneyRate: number;
  moneyToPointRate: number;
  pointsToRedeem: number;
  history: IRewardHistory[];
  rootStore: IRootStore;
  error: string;
  isFetching: boolean;
  fetchUser(contact: string): Promise<void>;
  clearUser(): void;
  redeemReward(): void;
  resetRedeem(): void;
}

export class RewardStore implements IRewardStore {
  rootStore: IRootStore;

  @observable user: IPublicUser = null;
  @observable points = 0;
  @observable redeemable = 0;
  @observable pointToMoneyRate = 0;
  @observable moneyToPointRate = 0;
  @observable pointsToRedeem = 0;
  @observable history: IRewardHistory[] = [];
  @observable error = null;
  @observable isFetching = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action
  fetchUser = async (contact: string) => {
    try {
      this.isFetching = true;
      this.error = null;
      const res = await fetchDetailUser(contact);

      runInAction(() => {
        if (res.user) {
          this.user = res.user;
          this.points = res.points;
          this.redeemable = res.redeemable;
          this.pointToMoneyRate = res.pointToMoneyRate;
          this.moneyToPointRate = res.moneyToPointRate;
          this.rootStore.vm.toggleScanModal(false);
          this.rootStore.vm.toggleTransactionHistoryPanel(false);
          this.rootStore.vm.toggleCheckoutPanel(true);
        } else {
          this.clearUser();
          this.error = `Cannot find customer ${contact}`;
        }
      });
    } catch (ex) {
      runInAction(() => {
        this.clearUser();
        this.error = `Failed to find customer ${contact}, try again`;
        alert(ex);
      });
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  };

  @action
  clearUser = () => {
    this.user = null;
    this.points = 0;
    this.redeemable = 0;
    this.pointsToRedeem = 0;
    this.history = [];
  };

  @action
  redeemReward = () => {
    this.resetRedeem();
    if (this.redeemable > 0) {
      const maxPointNeed = this.rootStore.checkout.subtotal / this.pointToMoneyRate;
      this.pointsToRedeem = this.redeemable > maxPointNeed ? maxPointNeed : this.redeemable;
      this.redeemable = this.redeemable - this.pointsToRedeem;
    }
  };

  @action
  resetRedeem = () => {
    this.redeemable = this.redeemable + this.pointsToRedeem;
    this.pointsToRedeem = 0;
  };
}
