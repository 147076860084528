import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { memo, FC } from 'react';
import styled from 'styled-components';
import { ITransaction } from '@markocen/bubblenation-base/models';

const CardContainer = styled<any>(Card)`
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border-bottom: 1px solid green;
  border-radius: 0;
  overflow: initial !important;
`;

interface IProps extends ITransaction {
  onClick(transactionId: string): void;
}

const TransactionHistoryCard: FC<IProps> = memo((props) => {
  return (
    <CardContainer>
      <CardActionArea onClick={() => props.onClick(props._id)}>
        <CardContent>
          {props.orderId && (
            <Typography variant="subtitle2" component="h4">
              ORDER #{props.orderId}
            </Typography>
          )}
          <Typography variant="subtitle1" component="p">
            {new Date(props.timestamp).toLocaleString()}
          </Typography>
          <Typography variant="subtitle1" component="p">
            Total: ${Number(props.total).toFixed(2)} | Tax: ${Number(props.tax).toFixed(2)} |
            Discount: ${Number(props.discount).toFixed(2)}{' '}
            {props.rewardPoints && `| Reward Points: ${props.rewardPoints}`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </CardContainer>
  );
});

export default TransactionHistoryCard;
