import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IRootStore, RootStore } from './root.store';
import { fetchActiveOrders, fetchTodayCompletedOrders } from '../services/onilne-order.service';
import { IOnlineOrder, OnlineOrderStatus } from '@markocen/bubblenation-base/models';

const FETCH_ACTIVE_ORDER_INTERVAL = 10000;

export interface IOnlineOrderStore {
  rootStore: IRootStore;
  orders: IOnlineOrder[];
  newOrders: IOnlineOrder[];
  confirmedOrders: IOnlineOrder[];
  completedOrders: IOnlineOrder[];
  selectedOrder: IOnlineOrder;
  isFetchingCompletedOrder: boolean;
  setSelectedOrder(order: IOnlineOrder): void;
  fetchActiveOrdersOnce(): Promise<void>;
  startFetchActiveOrders(): void;
  stopFetchActiveOrders(): void;
  fetchCompletedOrders(isReset?: boolean): Promise<void>;
}

export class OnlineOrderStore implements IOnlineOrderStore {
  rootStore: IRootStore;

  @observable orders: IOnlineOrder[] = [];

  @observable completedOrders: IOnlineOrder[] = [];

  @observable selectedOrder: IOnlineOrder = null;

  @computed get newOrders(): IOnlineOrder[] {
    return this.orders.filter((o) => {
      return o.status === OnlineOrderStatus.Parsed;
    });
  }

  @computed get confirmedOrders(): IOnlineOrder[] {
    return this.orders.filter((o) => {
      return o.status === OnlineOrderStatus.Doing;
    });
  }

  isFetchingCompletedOrder = false;

  private fetchActiveOrderTimer;

  private squareCompletedOrderCursor = '';

  private emailCompletedOrderCursor = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action
  setSelectedOrder = (order: IOnlineOrder): void => {
    this.selectedOrder = order;
  };

  private sortOrdersByPickupTime = (orders: IOnlineOrder[]): IOnlineOrder[] => {
    return orders.sort((a, b) => {
      return a.timestamp > b.timestamp ? -1 : 1;
    });
  };

  @action
  fetchActiveOrdersOnce = async (): Promise<void> => {
    try {
      const { session } = this.rootStore;
      const orders = await fetchActiveOrders({
        storeId: session.location,
        locationId: session.storeLocationId,
      });
      runInAction(() => {
        this.orders = this.sortOrdersByPickupTime(orders).reverse();
        this.selectedOrder =
          this.orders.find((o) => {
            return o._id === this.selectedOrder?._id;
          }) || this.selectedOrder;
      });
    } catch (ex) {
      //
    }
  };

  @action
  startFetchActiveOrders = (): void => {
    if (this.fetchActiveOrderTimer) {
      clearInterval(this.fetchActiveOrderTimer);
    }
    this.fetchActiveOrdersOnce();
    this.fetchActiveOrderTimer = setInterval(
      () => this.fetchActiveOrdersOnce(),
      FETCH_ACTIVE_ORDER_INTERVAL,
    );
  };

  stopFetchActiveOrders = (): void => {
    if (this.fetchActiveOrderTimer) {
      clearInterval(this.fetchActiveOrderTimer);
    }
  };

  @action
  fetchCompletedOrders = async (isReset?: boolean): Promise<void> => {
    try {
      this.isFetchingCompletedOrder = true;
      const { session } = this.rootStore;
      const { orders, emailOrderCursor, squareCursor } = await fetchTodayCompletedOrders({
        storeId: session.location,
        locationId: session.storeLocationId,
        squareCursor: isReset ? '' : this.squareCompletedOrderCursor,
        emailOrderCursor: isReset ? 0 : this.emailCompletedOrderCursor,
      });
      this.squareCompletedOrderCursor = squareCursor;
      this.emailCompletedOrderCursor = emailOrderCursor;
      runInAction(() => {
        this.completedOrders = isReset
          ? [...this.sortOrdersByPickupTime(orders)]
          : [...this.completedOrders, ...this.sortOrdersByPickupTime(orders)];
        this.selectedOrder =
          this.completedOrders.find((o) => {
            const selectedId = this.selectedOrder?._id;
            return o._id === selectedId;
          }) || this.selectedOrder;
      });
    } catch {
      //
    } finally {
      this.isFetchingCompletedOrder = false;
    }
  };
}
