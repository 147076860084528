import { CheckoutStore, ICheckoutStore } from './checkout.store';
import { hydrate } from './hydrate';
import { IMenuStore, MenuStore } from './menu.store';
import { IPromotionStore, PromotionStore } from './promotion.store';
import { IRewardStore, RewardStore } from './reward.store';
import { ISessionStore, SessionStore } from './session.store';
import { ITransactionStore, TransactionStore } from './transaction.store';
import { IViewModelStore, ViewModelStore } from './vm.store';
import { IOnlineOrderStore, OnlineOrderStore } from './online-order-store';

export interface IRootStore {
  vm: IViewModelStore;
  session: ISessionStore;
  menu: IMenuStore;
  checkout: ICheckoutStore;
  reward: IRewardStore;
  promotion: IPromotionStore;
  transaction: ITransactionStore;
  onlineOrder: IOnlineOrderStore;
}

export class RootStore implements IRootStore {
  vm: IViewModelStore;
  session: ISessionStore;
  menu: IMenuStore;
  checkout: ICheckoutStore;
  reward: IRewardStore;
  promotion: IPromotionStore;
  transaction: ITransactionStore;
  onlineOrder: IOnlineOrderStore;

  constructor() {
    this.vm = new ViewModelStore(this);
    this.session = new SessionStore(this);
    this.menu = new MenuStore(this);
    this.checkout = new CheckoutStore(this);
    this.reward = new RewardStore(this);
    this.promotion = new PromotionStore(this);
    this.transaction = new TransactionStore(this);
    this.onlineOrder = new OnlineOrderStore(this);

    hydrate('bubblenation_pos_session', this.session);
    hydrate('bubblenation_pos_checkout', this.checkout);
  }
}
