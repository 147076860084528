import React, { FC, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useStore } from '../../store';
import { observer } from 'mobx-react-lite';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import {
  getVendorText,
  mapOnlineOrderToOrderDetail,
  markOrderAsCanceled,
  markOrderAsConfirm,
  markOrderAsReady,
  refundOrder,
} from '../../services/onilne-order.service';
import { printLabel } from '../../services/printer.service';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';
import { OnlineOrderStatus } from '@markocen/bubblenation-base/models';

const ActionArea = styled<any>(DialogActions)`
  ${flexLayout({ justifyContent: 'space-between' })}
`;

const getDateString = (timestamp: number) => {
  const d = new Date(timestamp);
  return `${d.toLocaleTimeString()} ${d.toLocaleDateString()}`;
};

const OnlineOrderModal: FC = observer(() => {
  const isModalOpen = useStore((s) => s.vm.isOnlineOrderModalOpen);
  const closeModal = useStore((s) => s.vm.toggleOnlineOrderModal);
  const order = useStore((s) => s.onlineOrder.selectedOrder);
  const storeId = useStore((s) => s.session.location);
  const allModifiers = useStore((s) => s.menu.modifier);
  const allToppings = useStore((s) => s.menu.topping);
  const storeLocationId = useStore((s) => s.session.storeLocationId);
  const fetchActiveOrder = useStore((s) => s.onlineOrder.fetchActiveOrdersOnce);
  const [isPrinting, setPrinting] = useState(false);
  const vendorText = getVendorText(order ? order.vendor : 'square');

  const onPrintLabelsClicked = async () => {
    try {
      const trans = mapOnlineOrderToOrderDetail({
        order,
        modifiers: allModifiers,
        toppings: allToppings,
      });
      const [firstName, lastName] = order.name.split(' ');

      setPrinting(true);

      await printLabel(0, trans, {
        type: order.vendor,
        name: `${firstName}${lastName ? ` ${lastName[0].toUpperCase()}` : ''}`,
      });

      if (order.status !== OnlineOrderStatus.Finish && order.status !== OnlineOrderStatus.Cancel) {
        await markOrderAsConfirm({
          type: order.vendor === 'square' ? 'square' : 'emailOrder',
          orderId: order.vendor === 'square' ? order.vendorOrderId : order._id,
          storeId,
          locationId: storeLocationId,
        });
      }

      await fetchActiveOrder();
    } catch (e) {
      //
    } finally {
      setPrinting(false);
    }
  };

  const onMarkAsReadyClicked = async () => {
    await markOrderAsReady({
      type: order.vendor === 'square' ? 'square' : 'emailOrder',
      orderId: order.vendor === 'square' ? order.vendorOrderId : order._id,
      storeId,
      locationId: storeLocationId,
    });
    closeModal(false);
  };

  const onContentScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onRefundClicked = async () => {
    const ok = window.confirm('Are you sure to cancel and refund this order?');
    if (ok) {
      const reason = window.prompt('Reason of Refund? [Required]');
      if (!reason) {
        window.alert('Refund Canceled! No Reason Provided!');
        return;
      }

      await refundOrder({
        reason,
        orderId: order.vendorOrderId,
        locationId: storeLocationId,
      });
      await markOrderAsCanceled({
        type: order.vendor === 'square' ? 'square' : 'emailOrder',
        orderId: order.vendor === 'square' ? order.vendorOrderId : order._id,
        storeId,
        locationId: storeLocationId,
      });
      await fetchActiveOrder();

      closeModal(false);
    }
  };

  return (
    <Dialog open={isModalOpen} onClose={() => closeModal(false)}>
      {order && (
        <DialogContent style={{ width: '50vw' }} onScroll={onContentScroll}>
          <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
            {vendorText.text && (
              <Chip
                size="small"
                variant="outlined"
                label={vendorText.text}
                style={{
                  borderColor: vendorText.color,
                  color: vendorText.color,
                  marginRight: 5,
                }}
              />
            )}
            {order.name}
          </Typography>
          <Typography variant="subtitle1">{order.email}</Typography>
          <Typography variant="subtitle1">{order.phone}</Typography>
          <Typography variant="subtitle1">
            Pick up @ {getDateString(Number(order.pickupTime))}
          </Typography>
          <Typography variant="subtitle1">
            Order Create @ {getDateString(order.timestamp)}
          </Typography>
          <Typography variant="subtitle1">Total: ${order.total.toFixed(2)}</Typography>
          <br />
          <Typography variant="subtitle1" color="secondary">
            {order.note}
          </Typography>

          <br />
          {order.items.map((item) => (
            <>
              <Typography key={item._id} variant="subtitle1">
                {item._id} x{item.count}
              </Typography>
              <ul style={{ marginTop: 0 }}>
                {item.tags.map((tag) => (
                  <li key={tag}>{tag}</li>
                ))}
                {item.note && (
                  <li>
                    <Typography color="secondary" variant="body1">
                      {item.note}
                    </Typography>
                  </li>
                )}
              </ul>
            </>
          ))}
          <br />
          <br />
        </DialogContent>
      )}
      {!order && <DialogContent />}
      {order && (
        <ActionArea>
          <div />
          {/*<Button*/}
          {/*  color="primary"*/}
          {/*  disabled={*/}
          {/*    order.vendor !== 'square' ||*/}
          {/*    order.status === OnlineOrderStatus.Cancel ||*/}
          {/*    order.status === OnlineOrderStatus.Finish*/}
          {/*  }*/}
          {/*  size="small"*/}
          {/*  onClick={onRefundClicked}*/}
          {/*>*/}
          {/*  Cancel & Refund*/}
          {/*</Button>*/}
          <div>
            <Button
              color="primary"
              disabled={order.status !== OnlineOrderStatus.Doing}
              onClick={onMarkAsReadyClicked}
            >
              Mark as Ready
            </Button>
            <Button
              disabled={isPrinting}
              color="primary"
              variant="contained"
              onClick={onPrintLabelsClicked}
            >
              {isPrinting ? 'Printing...' : 'Print Labels'}
            </Button>
          </div>
        </ActionArea>
      )}
    </Dialog>
  );
});

export default OnlineOrderModal;
