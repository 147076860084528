import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { testLabelPrinter } from '../../services/printer.service';
import { useStore } from '../../store';

const SettingsModal = observer(() => {
  const rootStore = useStore();
  const closeModal = () => rootStore.vm.toggleSettingsModal(false);
  const onEnableLabelPrintingChange = (event, checked) =>
    rootStore.checkout.setEnableLabelPrinting(checked);
  const onTestLabelPrinter = () => testLabelPrinter();

  return (
    <Dialog
      transitionDuration={{ enter: 0, exit: 0 }}
      maxWidth="sm"
      fullWidth={true}
      open={rootStore.vm.isSettingsModalOpen}
      onClose={closeModal}
    >
      <DialogTitle>POS Settings</DialogTitle>
      <DialogContent>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={rootStore.checkout.enableLabelPrinting}
                onChange={onEnableLabelPrintingChange}
                value="checked"
                color="primary"
              />
            }
            label="Enable Label Printing"
          />
        </FormGroup>
        <FormGroup row>
          <Button onClick={onTestLabelPrinter} color="primary" variant="contained">
            Test Label Printer
          </Button>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export { SettingsModal };
