import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface IProps {
  title: string;
  value: any;
  options: { value: any; label: any }[];
  inputProps: {
    name: string;
    id: string;
  };
  onSelect(value: any): void;
}

export const OrderModifierSelect: FC<IProps> = memo(
  ({ title, inputProps, value, options, onSelect }) => {
    const classes = useStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id={inputProps.id}>
          {title}
        </InputLabel>
        <Select
          labelId={inputProps.id}
          id={inputProps.name}
          value={value}
          onChange={onSelect}
          labelWidth={labelWidth}
        >
          {options.map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
);
