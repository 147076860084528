import React, { FC } from 'react';
import OnlineOrderCard from './OnlineOrderCard';
import { useStore } from '../../store';
import { observer } from 'mobx-react-lite';

const NewOrderList: FC = observer(() => {
  const onlineOrderStore = useStore((s) => s.onlineOrder);
  const openOnlineOrderModal = useStore((s) => s.vm.toggleOnlineOrderModal);

  const onOrderSelect = (orderId: string) => {
    const order = onlineOrderStore.orders.find((o) => o._id === orderId);
    onlineOrderStore.setSelectedOrder(order || null);
    openOnlineOrderModal(true);
  };

  return (
    <div>
      {onlineOrderStore.orders.map((order) => (
        <OnlineOrderCard key={order._id} order={order} onClick={onOrderSelect} />
      ))}
    </div>
  );
});

export default NewOrderList;
