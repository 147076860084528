import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';
import { IMenuItem } from '@markocen/bubblenation-base/models';

const CardContainer = styled<any>(Card)`
  width: 100%;
  min-height: 100px;
`;

const CardAction = styled<any>(CardActionArea)`
  height: 100%;
  min-height: 100px;
`;

const Content = styled<any>(CardContent)`
  height: 100%;
  min-height: 100px;
  ${flexLayout({ justifyContent: 'center', direction: 'column', alignItems: 'center' })}

  h3 {
    line-height: 1.25;
    font-size: 1.2rem;
  }
`;

interface IProps {
  item: IMenuItem;
  wording: { name: string };
  chineseWording: { name: string };
  onClick(item: IMenuItem): void;
}

export const ItemCard: FC<IProps> = memo(({ item, onClick, wording }) => {
  return (
    <CardContainer variant="outlined">
      <CardAction onClick={() => onClick(item)}>
        <Content>
          {item.seasonal && (
            <Badge
              badgeContent={'Season'}
              color="primary"
              style={{ position: 'absolute', right: 30, top: 13 }}
            >
              {' '}
            </Badge>
          )}
          <Typography align={'center'} variant="h6" component="h3">
            {wording && wording.name ? wording.name : item._id}
          </Typography>
        </Content>
      </CardAction>
    </CardContainer>
  );
});
