import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useStore } from '../../store/index';

const PromotionSelectionModal: FC = observer(() => {
  const rootStore = useStore();

  const closeModal = () => rootStore.vm.togglePromotionModal(false);

  const selectPromotion = (id: string) => {
    const disabled = rootStore.reward.user ? rootStore.reward.pointsToRedeem > 0 : false;
    if (!disabled) {
      rootStore.promotion.setPromotion(id);
    }
  };

  const clearPromotion = () => rootStore.promotion.clearPromotion();

  const disableSelection = rootStore.reward.user ? rootStore.reward.pointsToRedeem > 0 : false;

  return (
    <Dialog open={rootStore.vm.isPromotionModalOpen} onClose={closeModal}>
      <DialogTitle>Select Promotion</DialogTitle>
      <DialogContent>
        <DialogContentText>Only one promotion can be applied at same time</DialogContentText>
        {disableSelection && (
          <DialogContentText color="error">
            Can't apply promotion, customer reward applied
          </DialogContentText>
        )}
        <FormControl>
          <RadioGroup
            value={
              rootStore.promotion.selectedPromotion ? rootStore.promotion.selectedPromotion.id : ''
            }
          >
            {rootStore.promotion.promotions.map((p) => (
              <FormControlLabel
                key={p.id}
                disabled={disableSelection}
                value={p.id}
                control={<Radio color="primary" />}
                label={p.name}
                onClick={() => selectPromotion(p.id)}
              />
            ))}
            <FormControlLabel
              disabled={disableSelection}
              value={''}
              control={<Radio color="primary" />}
              label="No Promotion"
              onClick={clearPromotion}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
});

export default PromotionSelectionModal;
