import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';

const CounterContainer = styled.div`
  ${flexLayout({})}
  margin-right: 50px;
`;

const CountLabel = styled.label`
  margin-right: 15px;
  margin-left: 15px;
  font-size: 25px;
  font-weight: bold;
`;

interface IProps {
  count: number;
  add(): void;
  minus(): void;
}

export const Counter: FC<IProps> = memo(({ count, add, minus }) => (
  <CounterContainer>
    <Fab color="primary" size="small" onClick={minus}>
      <RemoveIcon />
    </Fab>
    <CountLabel>{count}</CountLabel>
    <Fab color="primary" size="small" onClick={add}>
      <AddIcon />
    </Fab>
  </CounterContainer>
));
