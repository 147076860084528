import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import CheckoutDrawer from '../../components/CheckoutDrawer/CheckoutDrawer';
import ScannerModal from '../../components/ScannerModal/ScannerModal';
import { SettingsModal } from '../../components/SettingsModal/SettingsModal';
import SignInModal from '../../components/SignInModal/SignInModal';
import TopBar from '../../components/TopBar/TopBar';
import TransactionHistoryDrawer from '../../components/TransactionHistoryDrawer/TransactionHistoryDrawer';
import { flexLayout } from '../../lib/mixins';
import Menu from '../Menu/Menu';
import { useStore } from '../../store';
import OnlineOrderDrawer from '../../components/OnlineOrderDrawer/OnlineOrderDrawer';
import NewOrderIndicator from '../../components/NewOrderIndicator/NewOrderIndicator';
import InventoryTrackingModal from '../../components/InventoryTrackingModal';
import { notifySound } from '../../lib/sound';

const GlobalMask = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  ${flexLayout({ direction: 'column' })}
  color: white;
`;

const drawerWidth = '40vw';

const styles = (theme: Theme) => ({
  topBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  topBarShift: {
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  content: {
    width: '100vw',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
});

const App: FC<StyledComponentProps> = observer(({ classes }) => {
  const rootStore = useStore();
  const employee = rootStore.session.employee;
  const {
    isCheckoutPanelOpen,
    isTransactionHistoryPanelOpen,
    isOnlineOrderPanelOpen,
  } = rootStore.vm;

  const topBarClass = `${classes!.topBar} ${
    isCheckoutPanelOpen || isTransactionHistoryPanelOpen || isOnlineOrderPanelOpen
      ? classes!.topBarShift
      : ''
  }`;
  const menuContainerClass = `${classes!.content} ${
    isCheckoutPanelOpen || isTransactionHistoryPanelOpen || isOnlineOrderPanelOpen
      ? classes!.contentShift
      : ''
  }`;

  useEffect(() => {
    const initNotifySound = () => {
      notifySound.play();
      document.body.removeEventListener('click', initNotifySound);
    };
    document.body.addEventListener('click', initNotifySound);
  }, []);

  return (
    <>
      <CssBaseline />
      <SignInModal />
      <ScannerModal />
      <SettingsModal />
      <InventoryTrackingModal />
      {rootStore.vm.isPrintingLabel && (
        <GlobalMask>
          <h1>Printing Labels...</h1>
          <Button
            onClick={() => rootStore.vm.togglePrintingLabel(false)}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
        </GlobalMask>
      )}
      <TopBar className={topBarClass} />
      <CheckoutDrawer isOpen={rootStore.vm.isCheckoutPanelOpen} />
      <TransactionHistoryDrawer isOpen={rootStore.vm.isTransactionHistoryPanelOpen} />
      <OnlineOrderDrawer isOpen={rootStore.vm.isOnlineOrderPanelOpen} />
      <NewOrderIndicator />
      {employee && (
        <div className={menuContainerClass}>
          <Menu />
        </div>
      )}
    </>
  );
});

export default withStyles(styles)(App);
