import Button from '@material-ui/core/Button';
import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { flexLayout } from '../../lib/mixins';

const ItemFilterContainer = styled.div`
  padding: 1rem 0.5rem;
  ${flexLayout({ justifyContent: 'flex-start' })}
  flex-wrap: wrap;
`;

interface IProps {
  categories: string[];
  onChange(selectedCategory: string): void;
}

export const ItemFilter: FC<IProps> = memo(({ categories, onChange }) => {
  return (
    <ItemFilterContainer>
      {categories.map((category) => (
        <Button
          key={category}
          size="small"
          style={{ marginRight: '0.35rem', marginTop: '0.2rem' }}
          variant="contained"
          color={category === 'All' ? 'secondary' : 'primary'}
          onClick={() => onChange(category)}
        >
          {category === 'Soda' ? 'Sparking' : category}
        </Button>
      ))}
    </ItemFilterContainer>
  );
});
