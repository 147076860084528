import { get, post } from '../lib/ajax';
import { APIs } from '@markocen/bubblenation-base/models';

export async function fetchTransactions(timestamp: number, size: number) {
  return get(APIs.pos.transaction, { timestamp, size });
}

export async function linkTransaction(customerId: string, transactionId: string) {
  return post(APIs.pos.link, { customerId, transactionId });
}
