import { action, makeObservable, observable, runInAction } from 'mobx';
import { fetchTransactions, linkTransaction } from '../services/order.service';
import { IRootStore, RootStore } from './root.store';
import { ITransaction } from '@markocen/bubblenation-base/models';

export interface ITransactionStore {
  transactionHistory: ITransaction[];
  isFetchingTransaction: boolean;
  selectedTransaction?: ITransaction;
  loadTransactions(): void;
  openTransactionModal(transactionId: string): void;
  linkTransactionToUser(transactionId: string, userId: string): void;
}

export class TransactionStore implements ITransactionStore {
  rootStore: IRootStore;

  @observable transactionHistory: ITransaction[] = [];
  @observable isFetchingTransaction = false;
  @observable selectedTransaction: ITransaction | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action
  async loadTransactions() {
    try {
      this.isFetchingTransaction = true;
      const lastTimestamp =
        this.transactionHistory.length > 0
          ? this.transactionHistory[this.transactionHistory.length - 1].timestamp
          : Date.now();
      const transactions = await fetchTransactions(lastTimestamp, 20);
      runInAction(() => {
        this.transactionHistory = [...this.transactionHistory, ...transactions];
      });
    } catch (ex) {
      //
    } finally {
      runInAction(() => (this.isFetchingTransaction = false));
    }
  }

  @action
  openTransactionModal(transactionId: string) {
    this.selectedTransaction = this.transactionHistory.find((t) => t._id === transactionId);
    this.rootStore.vm.toggleTransactionModal(true);
  }

  @action
  async linkTransactionToUser(transactionId: string, userId: string) {
    try {
      const transaction = await linkTransaction(userId, transactionId);
      runInAction(() => {
        const index = this.transactionHistory.findIndex((t) => t._id === transactionId);
        const newHistory = [...this.transactionHistory];
        newHistory[index] = transaction;
        this.transactionHistory = newHistory;
        this.selectedTransaction = transaction;
      });
    } catch (ex) {
      alert(ex);
      throw new Error('Failed to find customer');
    }
  }
}
