import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { reprintLabel } from '../../services/printer.service';
import { fetchPublicUser } from '../../services/reward.service';
import { useStore } from '../../store/index';
import { IPublicUser } from '@markocen/bubblenation-base/models';

const ItemList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ContactInputContainer = styled.div`
  width: 90%;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
`;

const ContactInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  font-size: 20px;
`;

const TransactionModal: FC = observer(() => {
  const rootStore = useStore();
  const [isScanInputOpen, setIsScanInputOpen] = useState(false);
  const [isScanErrorShow, setIsScanErrorShow] = useState(false);
  const [publicUser, setPublicUser] = useState<IPublicUser>(null);

  let inputRef: HTMLInputElement;

  const closeModal = () => {
    setIsScanErrorShow(false);
    setIsScanInputOpen(false);
    setPublicUser(null);
    rootStore.vm.toggleTransactionModal(false);
  };

  const linkTransaction = async () => {
    try {
      setIsScanErrorShow(false);
      if (inputRef?.value?.trim()) {
        await rootStore.transaction.linkTransactionToUser(
          rootStore.transaction.selectedTransaction._id,
          inputRef.value.trim(),
        );
        displayUserInfo();
      }
    } catch (ex) {
      setIsScanErrorShow(true);
    } finally {
      if (inputRef) {
        inputRef.value = '';
      }
    }
  };

  const onScanInput = async (e) => {
    if (e.which === 13) {
      linkTransaction();
    }
  };

  const onLinkCustomerClick = () => {
    if (isScanInputOpen) {
      linkTransaction();
    } else {
      setIsScanInputOpen(true);
    }
  };

  const displayUserInfo = async () => {
    if (
      rootStore.transaction.selectedTransaction &&
      rootStore.transaction.selectedTransaction.customerId
    ) {
      const fetchedUser = await fetchPublicUser(
        rootStore.transaction.selectedTransaction.customerId,
      );
      setPublicUser(fetchedUser);
    }
  };

  const reprintLabels = async () => {
    if (rootStore.transaction.selectedTransaction.orderId) {
      reprintLabel(rootStore.transaction.selectedTransaction, rootStore.menu.menu);
    }
  };

  const { selectedTransaction } = rootStore.transaction;
  const { wordings } = rootStore.menu;

  return (
    <Dialog
      open={rootStore.vm.isTransactionModalOpen}
      onClose={closeModal}
      onEntered={displayUserInfo}
    >
      {selectedTransaction && (
        <DialogTitle>
          ORDER <b>#{selectedTransaction.orderId}</b>
        </DialogTitle>
      )}
      {selectedTransaction && (
        <DialogContent style={{ width: '50vw' }}>
          <DialogContentText>
            Time: {new Date(selectedTransaction.timestamp).toLocaleString()}
            <br />
            POS ID: {selectedTransaction.posId}
            {publicUser && (
              <>
                <br />
                Customer: {publicUser.email}
              </>
            )}
            {selectedTransaction.rewardPoints && (
              <>
                <br />
                Points: +{selectedTransaction.rewardPoints}
              </>
            )}
            <ItemList>
              {selectedTransaction.items.map((i) => (
                <li key={i._id}>
                  {wordings[i._id] ? wordings[i._id].name : i._id} x{i.count}
                </li>
              ))}
            </ItemList>
            Tax: ${Number(selectedTransaction.tax).toFixed(2)}
            <br />
            Discount: ${Number(selectedTransaction.discount).toFixed(2)}
            <br />
            Total: ${Number(selectedTransaction.total).toFixed(2)}
          </DialogContentText>
        </DialogContent>
      )}
      {isScanInputOpen && !selectedTransaction.customerId && (
        <ContactInputContainer>
          {isScanErrorShow && (
            <Typography variant="caption" component="p" color="error">
              Failed to find customer, try again.
            </Typography>
          )}
          <ContactInput
            type="email"
            ref={(r) => (inputRef = r)}
            autoFocus
            placeholder="Scan or type in customer's QR code or email"
            autoComplete="off"
            autoCorrect="none"
            onKeyPress={onScanInput}
          />
        </ContactInputContainer>
      )}
      {selectedTransaction && (
        <DialogActions>
          {!selectedTransaction.customerId && (
            <Button color="primary" onClick={onLinkCustomerClick}>
              Link Customer
            </Button>
          )}
          <Button color="primary" onClick={reprintLabels}>
            Re-print Labels
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
});

export default TransactionModal;
