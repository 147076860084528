import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC, memo } from 'react';

interface IProps {
  isOpen: boolean;
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm(): void;
  onCancel(): void;
}

const ConfirmModal: FC<IProps> = memo((props: IProps) => {
  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>{props.title}</DialogTitle>
      {props.description && (
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          {props.cancelText ? props.cancelText : 'Cancel'}
        </Button>
        <Button onClick={props.onConfirm} color="primary">
          {props.confirmText ? props.confirmText : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmModal;
