import { post, get } from '../lib/ajax';
import { APIs } from '@markocen/bubblenation-base/models';

export async function getEmployeeInfo(): Promise<{ name: string; _id: string }> {
  return get(APIs.pos.employee);
}

export async function fetchEmployeeByCode(
  code: string,
  location: string,
): Promise<{ employee: { name: string; _id: string } }> {
  return post(APIs.pos.login, { code, location });
}

export async function logoutEmployee(): Promise<boolean> {
  return post(APIs.pos.logout);
}
