import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { fetchMenu, fetchMenuWording, fetchModifier, fetchTopping } from '../services/menu.service';
import { IRootStore, RootStore } from './root.store';
import { IMenuItem, ITopping, Categories, IModifier } from '@markocen/bubblenation-base/models';
import { IWording } from '../models/menu.model';

export interface IMenuStore {
  menu: IMenuItem[];
  topping: ITopping[];
  modifier: IModifier[];
  wordings: IWording;
  chineseWordings: IWording;
  selectedItem?: IMenuItem;
  selectedFilter: string;
  readonly categories: string[];
  rootStore: IRootStore;
  refreshMenu(): void;
  setSelectedItem(item: IMenuItem): void;
  setSelectedFilter(category: string): void;
}

export class MenuStore implements IMenuStore {
  rootStore: IRootStore;

  @observable menu: IMenuItem[] = [];
  @observable topping: ITopping[] = [];
  @observable modifier: IModifier[] = [];
  @observable selectedItem: IMenuItem = null;
  @observable selectedFilter: string = 'All';
  @observable wordings: IWording = {};
  @observable chineseWordings: IWording = {};

  @computed get categories(): string[] {
    const categoryOrder: { [key in Categories]: number } = {
      'Milk Tea': 1,
      'Milk Foam': 2,
      Splash: 7,
      Soda: 3,
      Slush: 4,
      Tea: 5,
      Coffee: 8,
      Bakery: 6,
      'Protein Shake': 9,
    };
    return Object.values(Categories).sort((a, b) => categoryOrder[a] - categoryOrder[b]);
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action
  refreshMenu = async () => {
    const menu = await fetchMenu();
    const topping = await fetchTopping();
    const wordings = await fetchMenuWording();
    const modifier = await fetchModifier();
    const chineseWordings = await fetchMenuWording('zh');
    runInAction(() => {
      this.menu = menu;
      this.topping = topping;
      this.modifier = modifier;
      this.wordings = wordings;
      this.chineseWordings = chineseWordings;
    });
  };

  @action
  setSelectedItem = (item: IMenuItem) => {
    this.selectedItem = { ...item };
  };

  @action
  setSelectedFilter = (filter: string) => {
    this.selectedFilter = filter;
  };
}
