import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HistoryIcon from '@material-ui/icons/History';
import PowerOffIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import UserIcon from '@material-ui/icons/SettingsRemote';
import ShopIcon from '@material-ui/icons/ShoppingCart';
import MallIcon from '@material-ui/icons/LocalMall';
import InfoIcon from '@material-ui/icons/Info';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useStore } from '../../store';

interface IProps {
  className: string;
}

const TopBar: FC<IProps> = observer(({ className }) => {
  const rootStore = useStore();

  const onSettingsClick = () => {
    rootStore.vm.toggleSettingsModal(true);
  };

  const onInfoClick = () => {
    rootStore.vm.toggleInventoryAlertModal(true);
  };

  const onTransactionHistoryClick = () => {
    rootStore.vm.toggleCheckoutPanel(false);
    rootStore.vm.toggleOnlineOrderPanel(false);
    rootStore.vm.toggleTransactionHistoryPanel(!rootStore.vm.isTransactionHistoryPanelOpen);
    if (rootStore.transaction.transactionHistory.length <= 0) {
      rootStore.transaction.loadTransactions();
    }
  };

  const onScanClick = () => rootStore.vm.toggleScanModal(true);

  const onCheckoutClick = () => {
    rootStore.vm.toggleTransactionHistoryPanel(false);
    rootStore.vm.toggleOnlineOrderPanel(false);
    rootStore.vm.toggleCheckoutPanel(!rootStore.vm.isCheckoutPanelOpen);
  };

  const onOnlineOrderClick = () => {
    rootStore.vm.toggleTransactionHistoryPanel(false);
    rootStore.vm.toggleCheckoutPanel(false);
    rootStore.vm.toggleOnlineOrderPanel(!rootStore.vm.isOnlineOrderPanelOpen);
  };

  const onLogoutClick = () => {
    rootStore.vm.toggleSettingsModal(false);
    rootStore.session.signOut();
  };

  return (
    <AppBar position="fixed" className={className}>
      <Toolbar>
        <IconButton color="inherit" aria-label="Open Checkout Cart" onClick={onLogoutClick}>
          <PowerOffIcon />
        </IconButton>

        <Typography variant="h6" color="inherit" noWrap style={{ flex: 1 }}>
          {rootStore.session.employee
            ? `Cashier: ${rootStore.session.employee.name}`
            : 'Bubble Nation'}
        </Typography>

        <IconButton title="System Settings" color="inherit" onClick={onSettingsClick}>
          <SettingsIcon />
        </IconButton>

        <IconButton title="System Settings" color="inherit" onClick={onInfoClick}>
          <InfoIcon />
        </IconButton>

        <IconButton title="Transaction History" color="inherit" onClick={onTransactionHistoryClick}>
          <HistoryIcon />
        </IconButton>
        <IconButton title="Scan User" color="inherit" onClick={onScanClick}>
          <UserIcon />
        </IconButton>

        <IconButton title="Checkout" color="inherit" onClick={onOnlineOrderClick}>
          <MallIcon />
        </IconButton>

        <IconButton title="Checkout" color="inherit" onClick={onCheckoutClick}>
          <ShopIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
});

export default TopBar;
