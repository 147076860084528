export const flexLayout = ({
  direction = 'row',
  justifyContent = 'center',
  alignItems = 'center',
}) => `
    display: flex;
    flex-direction: ${direction};
    align-items: ${alignItems};
    justify-content: ${justifyContent};
`;
