import React, { FC, useEffect } from 'react';
import OnlineOrderCard from './OnlineOrderCard';
import { useStore } from '../../store';
import { observer } from 'mobx-react-lite';

const CompletedOrderList: FC = observer(() => {
  const onlineOrder = useStore((s) => s.onlineOrder);
  const openOnlineOrderModal = useStore((s) => s.vm.toggleOnlineOrderModal);

  useEffect(() => {
    onlineOrder.fetchCompletedOrders(true);
  }, []);

  const onOrderSelect = (orderId: string) => {
    const order = onlineOrder.completedOrders.find((o) => o._id === orderId);
    onlineOrder.setSelectedOrder(order || null);
    openOnlineOrderModal(true);
  };

  return (
    <div>
      {onlineOrder.completedOrders.map((order) => (
        <OnlineOrderCard key={order._id} order={order} onClick={onOrderSelect} />
      ))}
    </div>
  );
});

export default CompletedOrderList;
