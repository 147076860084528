import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import { StoreProvider } from './store';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: { main: '#007849' },
  },
  // @ts-ignore
  shadows: [...new Array(25)].map((s) => 'none'),
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none',
  },
});

ReactDOM.render(
  <StoreProvider>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </StoreProvider>,
  document.getElementById('root'),
);
